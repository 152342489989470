import { FC } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
// eslint-disable-next-line max-len
import { PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes } from 'store/models/APIModels';
import { TextField } from '@modulor/react';
import { useCustomTranslation } from 'Modulor/hooks';
import { FieldType, getPattern } from 'utils';
import { getErrorKey } from '../fieldErrors';
import { getAdditionalRules } from './utils';

interface InputFieldProps {
  name: string;
  label?: string | null;
  control: Control;
  regexp?: string;
  isRequired?: boolean;
  extendedType?: ExtendedTypes;
  placeHolder?: string;
  defaultValue: string | null;
  isCheckout: boolean;
  isDisabled?: boolean;
  validationMsgKey?: string;
}

const numericFieldExtendedTypes = [
  ExtendedTypes.CardPan,
  ExtendedTypes.CardExpYear,
  ExtendedTypes.CardExpMonth,
];

export const InputField:FC<InputFieldProps> = (props) => {
  const {
    name,
    label,
    placeHolder = '',
    control,
    isRequired,
    regexp,
    extendedType,
    isCheckout,
    isDisabled = false,
    defaultValue,
    validationMsgKey,
  } = props;
  const { t } = useCustomTranslation();
  const isNumeric = extendedType && numericFieldExtendedTypes.includes(extendedType as ExtendedTypes);
  const type = isNumeric && isCheckout ? 'number' : 'text';
  const inputMode = isNumeric && isCheckout ? 'numeric' : 'text';
  const pattern = getPattern(regexp, extendedType);

  const additionalParams = getAdditionalRules({
    extendedType,
  });

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      rules={{
        required: isRequired,
        pattern,
        ...additionalParams,
      }}
      render={({ field, fieldState }: FieldValues) => {
        const errorMessageKey = getErrorKey(FieldType.input, fieldState, validationMsgKey);
        const errorMessage = t(errorMessageKey);

        return (
          <TextField
            {...field}
            name={name}
            placeholder={t(placeHolder)}
            error={errorMessage}
            label={t(label || name)}
            type={type}
            inputMode={inputMode}
            disabled={isDisabled}
            readOnly={isDisabled}
          />
        );
      }}
    />
  );
};
