export interface WithdrawalRejectRequestResponse {
  success_amount: number;
  fail_amount: number;
}

export interface WithdrawalRejectState {
  amount?: number;
  currency?: string;
  isConfirmOpen: boolean;
  isFetching: boolean;
  response?: WithdrawalRejectRequestResponse;
}

export enum WithdrawalRejectRequestStatus {
  success,
  failed,
}
