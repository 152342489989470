/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useRef, useEffect, useState, LegacyRef } from 'react';
import clx from 'classnames';
import DevControlsSideMenu from './DevControlsSideMenu';

import './DevViewPage.scss';

const DevView = () => {
  const [open, setOpen] = useState(true);
  const [contentHeight, setContentHeight] = useState('750px');
  const cashierRef = useRef<HTMLIFrameElement | null>();

  const setMethod = (method: string) => {
    if (method === 'checkout') {
      cashierRef.current!.setAttribute('src', 'checkout');

      localStorage.setItem('method', method);
    } else if (method === 'reset') {
      cashierRef.current!.setAttribute('src', 'reset');
      localStorage.clear();
      window.location.reload();
    } else if (method === 'success-deposit') {
      cashierRef.current!.setAttribute('src', '/deposit/deposit-success');
      localStorage.setItem('method', method);
    } else if (method === 'error-deposit') {
      cashierRef.current!.setAttribute('src', '/deposit/deposit-error');
      localStorage.setItem('method', method);
    } else {
      fetch(`http://localhost:9000/${method}`)
        .then((response) => response.json())
        .then((query) => {
          const natashaHeight = '&width=911&height=851';
          const formattedQuery = query.replace('metrics=0', 'metrics=1');
          cashierRef.current!.setAttribute('src', `${method}?${formattedQuery}${natashaHeight}`);

          localStorage.setItem('method', method);
        })
        .catch(() => {
          cashierRef.current!.setAttribute('src', `${method}`);
        });
    }
  };

  useEffect(() => {
    document.body.classList.remove('is-loading');
    window.addEventListener('message', (m) => {
      if (typeof m.data !== 'string') {
        return;
      }

      try {
        const msg = JSON.parse(m.data);
        if (msg.id === 'frameChangedHeight' && cashierRef.current && msg.value) {
          setContentHeight(`${msg.value}px`);
          cashierRef.current.height = `${msg.value}px`;
          const msgBox = document.querySelector('.messages');
          msgBox!.innerHTML += `<li>frameChangedHeight: ${msg.value}</li>`;
          msgBox!.scrollTop = msgBox!.scrollHeight;
        }
      } catch (e) {
        console.error('Error parsing JSON:', e);
      }
    });
  }, []);

  return (
    <div
      className={clx('root', {
        'root__menu-open': open,
      })}
    >
      <label
        className="menu-btn"
        onClick={() => setOpen(!open)}
      >
        <span />
      </label>
      <iframe
        ref={cashierRef as unknown as LegacyRef<HTMLIFrameElement> | undefined}
        title="iframe for cashier"
        id="demeter"
        width="100px"
        height={contentHeight}
        data-hj-allow-iframe
      />
      <DevControlsSideMenu
        setMethod={setMethod}
        isOpen={open}
      />
    </div>
  );
};

export default DevView;
