/* eslint-disable camelcase */
import {
  getIsOpenedInPopUp,
  getMerchantRedirectUrlForTransactionProcessing,
  inIframe,
  parseQuery,
  softLocalStorageGet,
  stringToInt,
} from 'utils';
import {
  ALT_METHODS,
  REDIRECT_FLOW_TYPES,
  RedirectFlowType,
  SELECTED_METHOD_ID,
  SUBMIT_FORM_ACTIONS,
} from 'store/constants';
import { FlatStringObject } from 'commonTypes';
import {
  PayHubCashierContractsCashier3DSResultResponse,
  PayHubCashierContractsEnumsCashierActionType,
  PayHubDemeterContractsSubmitPaymentResponse,
  PayHubDemeterContractsSubmitPayoutResponse,
  PayHubDomainSessionsEnumsPlatformType,
  ProcessingTimeDate,
} from 'store/models';
import IntegrationCommunication from 'services/integration-communication';
import { UIPaymentGroups } from 'store/formatters';

export const formatParamsForForm = (
  // eslint-disable-next-line max-len
  redirectForm: PayHubDemeterContractsSubmitPaymentResponse | PayHubDemeterContractsSubmitPayoutResponse | PayHubCashierContractsCashier3DSResultResponse,
) => {
  const {
    request_method: requestMethod,
    request_url: requestUrl,
    request_fields: requestFields,
  } = redirectForm;
  const defaultReturn = {
    requestUrl,
    requestFields,
  };

  try {
    const isEmptyFields = typeof requestFields === 'object' && Object.keys(requestFields).length === 0;
    if ((requestMethod || '').toLowerCase() === 'get' && isEmptyFields) {
      const url = new URL(requestUrl || '');
      const GETRequestFields: FlatStringObject = {};
      url.search.substring(1).split('&').forEach((value) => {
        const [key, param] = value.split('=');
        GETRequestFields[key] = param;
      });
      const isFormatWithProtocol = url.origin === 'null' || !url.protocol.includes('http');
      const formattedRequestUrl = `${isFormatWithProtocol ? url.protocol : url.origin}${url.pathname}`;

      return {
        requestUrl: formattedRequestUrl,
        requestFields: GETRequestFields,
      };
    }
  } catch {
    return defaultReturn;
  }

  return defaultReturn;
};

export interface FetchPaymentSessionStateCreatorProps {
  debouncedFetchingDeclineReasons: () => void;
  isDeclineReasonFetchible: boolean;
  transaction_status: string | null | undefined;
  isTransactionStatusProcessing: boolean;
  isPaymentSessionStateFetching: boolean | null | undefined;
  isDeposit: boolean;
  isLLA?: boolean;
  error_fatal?: boolean;
  action?: string;
  result_status?: string;
}

interface GetFormTargetProps {
  action?: PayHubCashierContractsEnumsCashierActionType;
  isActionExpected: boolean;
  redirectType?: RedirectFlowType;
  isLLA?: boolean;
}
export const getFormTarget = (props:GetFormTargetProps):string => {
  const {
    action,
    isActionExpected,
    redirectType,
    isLLA,
  } = props;
  if (isLLA) {
    return '_self';
  }

  if (isActionExpected && redirectType) {
    return REDIRECT_FLOW_TYPES[redirectType];
  }

  return action && (action as string === SUBMIT_FORM_ACTIONS.redirect) ? '_self' : '_blank';
};

export const getDomainWithLang = (url: unknown): string => {
  if (typeof url !== 'string') {
    return '';
  }

  const urlPartsArr = url.split('/');
  const output = urlPartsArr.reduce((acc, curr, i) => {
    if (i === 0) {
      return curr;
    }

    if (i <= 3) {
      return `${acc}/${curr}`;
    }

    return acc;
  }, '');

  if (output.includes('http')) {
    return output;
  }

  return `https://${output}`;
};

export const getUrlToClose = ():string => {
  const queryObj = parseQuery(window.location.search) as { merchant_domain: string };
  const merchantUrl = softLocalStorageGet('merchant_url') ?? queryObj.merchant_domain;
  const origin = getDomainWithLang(merchantUrl);
  const originHasLanguage = origin.split('/').some((e) => e.length === 2);

  if (originHasLanguage) {
    const originWithoutLanguage = origin.slice(0, -3);

    return `${originWithoutLanguage}/native-application/payment-redirect?type=deposit&result=success`;
  }

  return `${origin}/native-application/payment-redirect?type=deposit&result=success`;
};

interface StatusRedirectOnProcessing {
  platform: PayHubDomainSessionsEnumsPlatformType;
  isSuccess?: boolean;
  urlToRedirect?: string;
}
export const statusRedirectOnProcessing = (props: StatusRedirectOnProcessing): void => {
  const {
    platform,
    isSuccess,
    urlToRedirect = '',
  } = props;
  const isInWeb = inIframe();

  if (getIsOpenedInPopUp() && isInWeb) {
    IntegrationCommunication.sendMessage({
      id: 'reInitCashier',
    });
    IntegrationCommunication.sendMessage({
      id: 'closeCashier',
    });

    return;
  }

  if (isInWeb) {
    IntegrationCommunication.sendMessage({
      id: 'redirectTo',
      value: '/',
    });
  } else {
    // TODO: should be implemented reInit for native
    if (platform.includes('native')) {
      const closeUrl = (!!urlToRedirect && urlToRedirect !== '/') ? urlToRedirect : getUrlToClose();
      // TODO: get result URL from API, when it will be implemented
      window.location.assign(closeUrl);
      return;
    }

    const merchant_redirect_url = getMerchantRedirectUrlForTransactionProcessing(
      isSuccess || false,
    );
    const decodedUrl = urlToRedirect || decodeURI(merchant_redirect_url);
    window.location.href = /http|:\/\//.test(decodedUrl) || decodedUrl === '/' ? decodedUrl : `//${decodedUrl}`;
  }
};

export const getProcessingTimeData = (): ProcessingTimeDate | null => {
  const methods = softLocalStorageGet(ALT_METHODS);

  if (!methods?.length) {
    return null;
  }

  const selectedBeforeMethodId = stringToInt(localStorage.getItem(SELECTED_METHOD_ID) || '');

  try {
    const parsed: UIPaymentGroups[] = JSON.parse(methods) as UIPaymentGroups[];
    const selectedMethodIndex:number | undefined = parsed.findIndex(({
      id,
    }) => id === selectedBeforeMethodId);
    if (parsed[selectedMethodIndex]) {
      return {
        processingTime: parsed[selectedMethodIndex].processingTimeTranslationKey
          || null,
        showProcessingTime: parsed[selectedMethodIndex].showProcessingTime || null,
      };
    }

    return null;
  } catch {
    return null;
  }
};

export const closeCashierAfterOpenInNewTab = (redirectType?: string): void => {
  if (getIsOpenedInPopUp() && redirectType === 'blank') {
    setTimeout(() => {
      IntegrationCommunication.sendMessage({
        id: 'closeCashier',
        value: {
          depositPrevent: false,
        },
      });
    }, 500);
  }
};
