import { FC } from 'react';
import { PayHubCashierContractsTaxResponse } from 'store/models';
import { calculateBiggest } from 'store/formatters';
import { TaxInfo } from './TaxInfo';

import './TaxInfo.scss';

export interface TaxContainerProps {
  tax?: PayHubCashierContractsTaxResponse;
  calculateNonTaxableAmount: number;
  taxAmount: number;
  amount?: number;
  isTaxHintShown: boolean;
}

export const TaxContainer: FC<TaxContainerProps> = (props) => {
  const {
    tax,
    calculateNonTaxableAmount,
    taxAmount,
    amount,
    isTaxHintShown,
  } = props;

  const { currency } = tax || {};
  const percentTax = tax?.tiers?.length && calculateBiggest(tax.tiers)?.percent_value;

  if (
    (!amount || !percentTax)
      || (!tax
        && !isTaxHintShown)
      || (calculateNonTaxableAmount >= amount)
  ) {
    return null;
  }

  return (
    <div
      className="tax-info-container"
    >
      <TaxInfo
        name={tax?.name}
        description={tax?.description}
        percentTax={percentTax}
        currency={currency}
        isTaxHintShown={isTaxHintShown}
        taxValueAmount={taxAmount}
      />
    </div>
  );
};
