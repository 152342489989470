import { useEffect } from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import {
  getLinkTargetType,
  isContentPageUrl,
  navigateForInfo,
  SelectInfoMethodProps,
  transformContentUrl,
} from '../utils';
import { UIPaymentGroups, UIPayoutGroups } from '../store/formatters';
import {
  PayHubCashierContractsPaymentsSelectPaymentGroupResponse, PayHubDemeterContractsPayoutsSelectPayoutGroupResponse,
  PayHubDomainSessionsEnumsPlatformType,
} from '../store/models';

interface OriginalArgs {
  payGroupId: number;
  currency: string;
}

interface StatusObject {
  status?: string;
  endpointName?: string;
  requestId?: string;
  originalArgs?: OriginalArgs;
  startedTimeStamp?: number;
  // eslint-disable-next-line max-len
  data?: PayHubCashierContractsPaymentsSelectPaymentGroupResponse | PayHubDemeterContractsPayoutsSelectPayoutGroupResponse;
  fulfilledTimeStamp?: number;
  isUninitialized?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  // eslint-disable-next-line max-len
  currentData?: PayHubCashierContractsPaymentsSelectPaymentGroupResponse | PayHubDemeterContractsPayoutsSelectPayoutGroupResponse;
  isFetching?: boolean;
}

const useSelectInfoMethod = (
  methods: UIPaymentGroups[] | UIPayoutGroups[],
  resultDepositMethodInfo: StatusObject,
  dispatch:Dispatch,
  platform?: PayHubDomainSessionsEnumsPlatformType,
) => {
  const selectInfoMethod = (props: SelectInfoMethodProps): void => {
    const {
      descriptionUrl,
      isSelfTarget,
    } = props;

    if (!descriptionUrl) {
      return;
    }

    const transformedUrl = transformContentUrl(descriptionUrl);
    const isContentPage = isContentPageUrl(transformedUrl);
    const linkTarget = getLinkTargetType({
      platform,
      isSelfTarget,
    });

    navigateForInfo({
      isContentPage,
      isSelfTarget,
      dispatch,
      linkTarget,
      descriptionUrl: transformedUrl,
    });
  };

  useEffect(() => {
    const currentMethod = methods.find((method) => method.id === resultDepositMethodInfo?.originalArgs?.payGroupId);
    const descriptionUrl = resultDepositMethodInfo?.data?.info;
    const isSelfTarget = !!currentMethod?.isSelfTarget;

    if (!resultDepositMethodInfo.isFetching) {
      selectInfoMethod({
        descriptionUrl,
        isSelfTarget,
        dispatch,
        platform,
      });
    }
  }, [
    resultDepositMethodInfo.isFetching,
    methods,
  ]);

  return selectInfoMethod;
};

export default useSelectInfoMethod;
