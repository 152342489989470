import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { depositAPI, extendedDepositAPI } from '../services/depositAPI';
import { withdrawalAPI, extendedWithdrawalAPI } from '../services/withdrawalAPI';
import { checkAndSetIsSessionExpired } from '../slices/global';

export const listener = createListenerMiddleware();

listener.startListening({
  matcher: isAnyOf(
    depositAPI.endpoints?.getDepositMethods.matchFulfilled,
    extendedDepositAPI.endpoints?.getDepositMethodInfo.matchFulfilled,
    extendedDepositAPI.endpoints?.submitDeposit.matchFulfilled,
    withdrawalAPI.endpoints?.getWithdrawalMethods.matchFulfilled,
    extendedWithdrawalAPI.endpoints?.submitWithdrawal.matchFulfilled,
    extendedWithdrawalAPI.endpoints?.getWithdrawalMethodInfo.matchFulfilled,
  ),
  effect: async (action, listenerAPI) => {
    listenerAPI.dispatch(checkAndSetIsSessionExpired());
  },
});
