/* eslint-disable react/no-unknown-property */
import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import clx from 'classnames';
import { formatMethodName, resetFocus } from 'utils';

import './SubmitForm.scss';

const sandboxAttrs = [
  'allow-top-navigation-by-user-activation',
  'allow-top-navigation',
  'allow-forms',
  'allow-modals',
  'allow-popups',
  'allow-scripts',
  'allow-same-origin',
].join(' ');

class CustomIframe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mountNode: null,
      lastHostWidth: '',
    };

    this.setContentRef = (contentRef) => {
      setTimeout(() => {
        const win = contentRef?.contentWindow;
        if (win) {
          const styleEls = win.parent.document.querySelectorAll('style');
          const linkEls = win.parent.document.querySelectorAll('link');

          if (styleEls.length) {
            styleEls.forEach((el) => {
              const clone = el.cloneNode(true);
              win.document.head.appendChild(clone);
            });
          }

          if (linkEls.length) {
            linkEls.forEach((el) => {
              const clone = el.cloneNode(true);
              win.document.head.appendChild(clone);
            });
          }
        }
        this.setState({
          mountNode: contentRef?.contentWindow?.document?.body,
        });
      }, 1000);
    };
  }

  componentDidMount() {
    const host = document.getElementById('iframe-host');

    if (host) {
      this.setState({
        lastHostWidth: host.style.width,
      }, () => {
        host.style.width = '100%';
      });
    }
  }

  componentWillUnmount() {
    const host = document.getElementById('iframe-host');

    if (host) {
      const { lastHostWidth } = this.state;
      host.style.width = lastHostWidth;
    }

    resetFocus();
  }

  render() {
    const { children, methodName } = this.props;
    const { mountNode } = this.state;
    const name = formatMethodName(methodName);

    return (
      <iframe
        title="innerCashier"
        className={clx('custom-iframe', {
          [name]: name,
        })}
        ref={this.setContentRef}
        {...this.props}
        frameBorder="0"
        sandbox={sandboxAttrs}
        allow="camera; clipboard-write *;"
        data-hj-allow-iframe="true"
        allowpaymentrequest="true"
      >
        {' '}
        { mountNode && createPortal(children, mountNode) }
      </iframe>
    );
  }
}
export default CustomIframe;
