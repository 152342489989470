import { FC } from 'react';
import { Typography } from '@modulor/react';
import { ProcessingTimeDate } from 'store/models';
import { useCustomTranslation } from 'Modulor/hooks';

interface Props {
  processingTimeData: ProcessingTimeDate | null;
  type: string;
}

export const TransactionAdditionalText: FC<Props> = (
  props,
) => {
  const {
    processingTimeData,
    type,
  } = props;
  const { t } = useCustomTranslation();

  if (!processingTimeData) {
    return null;
  }

  const {
    processingTime,
  } = processingTimeData || {};

  const time = processingTime ? t(`${processingTime}`) : '';

  return processingTime ? (
    <Typography variant="body-regular">
      {t(`PH.${type.toUpperCase()}.STATUS_PAGE.PROCESSING.BODY`, {
        time,
      })}
    </Typography>
  ) : null;
};
