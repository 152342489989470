import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  PayHubCashierContractsWithdrawalReverseResponse,
  WithdrawalRejectRequestResponse,
  WithdrawalRejectState,
} from '../models';

export const initialState: WithdrawalRejectState = {
  isConfirmOpen: false,
  isFetching: false,
};

const withdrawalRejectSlice = createSlice({
  name: 'withdrawalReject',
  initialState,
  reducers: {
    setWithdrawalRejectInfo(state, action: PayloadAction<PayHubCashierContractsWithdrawalReverseResponse | undefined>) {
      if (!action.payload) {
        return state;
      }

      return {
        ...state,
        ...action.payload,
      };
    },
    setWithdrawalRejectRequestResponse(state, action: PayloadAction<WithdrawalRejectRequestResponse | undefined>) {
      return {
        ...state,
        response: action.payload,
      };
    },
    openWithdrawalRejectConfirmationDialog(state) {
      return {
        ...state,
        isConfirmOpen: true,
      };
    },
    closeWithdrawalRejectConfirmationDialog(state) {
      return {
        ...state,
        isConfirmOpen: false,
      };
    },
    startWithdrawalRejectRequestFetching(state) {
      return {
        ...state,
        isFetching: true,
      };
    },
    stopWithdrawalRejectRequestFetching(state) {
      return {
        ...state,
        isFetching: false,
      };
    },
  },
});

export const withdrawalRejectInfoSelector = (
  { withdrawalReject }: { withdrawalReject: WithdrawalRejectState },
) => ({
  amount: withdrawalReject.amount,
  currency: withdrawalReject.currency,
  isConfirmOpen: withdrawalReject.isConfirmOpen,
  isFetching: withdrawalReject.isFetching,
});

export const isConfirmOpenSelector = (
  { withdrawalReject }: { withdrawalReject: WithdrawalRejectState },
) => withdrawalReject.isConfirmOpen;

export const isWithdrawalRejectRequestFetchingSelector = (
  { withdrawalReject }: { withdrawalReject: WithdrawalRejectState },
) => withdrawalReject.isFetching;

export const withdrawalRejectRequestResponseSelector = (
  { withdrawalReject }: { withdrawalReject: WithdrawalRejectState },
) => withdrawalReject.response;

export const {
  setWithdrawalRejectInfo,
  setWithdrawalRejectRequestResponse,
  openWithdrawalRejectConfirmationDialog,
  closeWithdrawalRejectConfirmationDialog,
  startWithdrawalRejectRequestFetching,
  stopWithdrawalRejectRequestFetching,
} = withdrawalRejectSlice.actions;

export default withdrawalRejectSlice.reducer;
