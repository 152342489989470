import { useEffect, FC } from 'react';
import clx from 'classnames';
import IntegrationCommunication from 'services/integration-communication';
import { Button, Typography } from '@modulor/react';
import { ProcessingTimeDate } from 'store/models';
import { useAppSelector } from 'store/hooks';
import { platformSelector } from 'store/slices/global';
import { useCustomTranslation } from 'Modulor/hooks';
import { FlowTypes } from 'store/constants';
import { TransactionAdditionalText } from './TransactionAdditionalText';
import { statusRedirectOnProcessing } from '../components/SubmitForm/utils';
import ProcessingStatusIcon from '../../assets/ProcessingStatusIcon';

type TransactionProcessingProps = {
  processingTimeData: ProcessingTimeDate | null;
  stopTimer: boolean;
  urlToRedirect: string;
};

let timer: NodeJS.Timeout;

export const TransactionProcessing: FC<TransactionProcessingProps> = (props) => {
  const {
    processingTimeData,
    stopTimer,
    urlToRedirect,
  } = props;
  const { t } = useCustomTranslation();
  const platform = useAppSelector(platformSelector);
  const flowType = window.location.href.includes('deposit') ? FlowTypes.deposit : FlowTypes.withdrawal;
  const redirectCallback = () => {
    const merchantNode = document.querySelector('#iframe-host');
    clearTimeout(timer);

    if (!merchantNode) {
      statusRedirectOnProcessing({
        platform,
        urlToRedirect,
      });
      return;
    }

    timer = setTimeout(redirectCallback, 30000);
  };

  useEffect(() => {
    IntegrationCommunication.sendMessage({
      id: 'depositResult',
      value: 'success',
    });
    timer = setTimeout(redirectCallback, 30000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (stopTimer) {
      clearTimeout(timer);
    }
  }, [stopTimer]);

  return (
    <section
      className={clx('status', 'transaction-processing')}
    >
      <div
        className="status-icon-wrapper"
      >
        <ProcessingStatusIcon />
      </div>
      <Typography
        variant="title-2-semibold"
      >
        {t(`PH.${flowType.toUpperCase()}.STATUS_PAGE.PROCESSING.TITLE`)}
      </Typography>
      <TransactionAdditionalText
        processingTimeData={processingTimeData}
        type={flowType}
      />
      <div className="bottom-button-wrapper">
        <Button
          className="bottom-button"
          onClick={redirectCallback}
        >
          {t('PH.BUTTON.BACK_TO_GAME')}
        </Button>
      </div>
    </section>
  );
};
