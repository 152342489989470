/* eslint-disable camelcase */
import qs from 'query-string';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from 'store';
import { setReqHeaders } from 'utils/common';
import { ContentPageRequest, ContentPageResponse } from '../models/contentPageAPI';

export const contentPageAPI = createApi({
  reducerPath: 'contentPageAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: '/',
    prepareHeaders(headers, { getState }) {
      const { session_id = '' } = qs.parse(window?.location?.search || '');
      const { sessionId } = (getState() as RootState).common;
      setReqHeaders(headers, sessionId || `${session_id}`);
    },
  }),
  endpoints(builder) {
    return {
      contentPage: builder.query< ContentPageResponse, ContentPageRequest >({
        query: ({ url }) => url,
      }),
    };
  },
});

export const {
  useLazyContentPageQuery,
} = contentPageAPI;
export default contentPageAPI.reducer;
