/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import { MouseEvent } from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { UIPaymentGroups, UIPayout, UIPayoutGroups } from 'store/formatters';
import { setSelectedPayGroupId } from 'store/slices/global';
import {
  Limits,
  PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType,
  PayHubDomainSessionsEnumsPlatformType, ProcessingCurrency,
} from 'store/models';
import { NavigateFunction } from 'react-router-dom';
import { getPrettierFormatNumber, SelectActionWithdrawalMethodProps, softLocalStorageSet } from 'utils';
import { setCurrentMethod } from 'store/slices/withdrawal';
import { FlowTypes, SELECTED_METHOD_ID } from 'store/constants';
import { redirectToSelectedMethod } from '../routerUtils';
import { Amount, getConversionText } from '../Deposit/utils';
import { TFunction } from '../../commonTypes';

export const selectActionMethod = (props: SelectActionWithdrawalMethodProps): void => {
  const {
    method,
    navigate,
    dispatch,
    flowType,
  } = props;
  dispatch(setCurrentMethod(method));
  redirectToSelectedMethod(method.id, navigate, flowType);
};

interface SelectWithdrawalMethodHandlerProps {
  method: UIPaymentGroups | UIPayoutGroups;
  platform?: PayHubDomainSessionsEnumsPlatformType;
  dispatch: Dispatch;
  navigate: NavigateFunction;
  flowType: FlowTypes;
}

const METHODS_WITH_FORM: string[] = [
  PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType.Activity,
  PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType.NestedList,
];

export const selectWithdrawalMethodHandler = (props: SelectWithdrawalMethodHandlerProps, event?: MouseEvent): boolean | undefined => {
  const {
    method,
    dispatch,
    navigate,
    flowType,
  } = props;

  if (!method.enabled) {
    event?.stopPropagation();
    event?.preventDefault();
    return false;
  }

  dispatch(setSelectedPayGroupId(method.id));
  softLocalStorageSet(SELECTED_METHOD_ID, JSON.stringify(method.id));

  if (METHODS_WITH_FORM.includes(method.payGroupActionType)) {
    selectActionMethod({
      method,
      dispatch,
      navigate,
      flowType,
    });
  }

  return undefined;
};

export const getMaxLimit = (limits: Limits | null, userBalance: number): number | undefined => {
  if (userBalance > (limits?.min || 0)) {
    return ((limits?.max && limits?.max > userBalance) || !limits?.max) ? userBalance : limits?.max;
  }

  return limits?.max || undefined;
};

interface WithdrawalSubmitTextProps {
  t: TFunction;
  amount: Amount;
  currency: string;
  taxAmount?: number | string;
  processingCurrency?: ProcessingCurrency;
  processingCurrencyRate?: number;
}
export const getWithdrawalSubmitSubText = (props: WithdrawalSubmitTextProps) => {
  const {
    t,
    amount,
    currency,
    taxAmount,
    processingCurrency,
    processingCurrencyRate,
  } = props;

  if (processingCurrency && processingCurrencyRate) {
    return getConversionText(amount, processingCurrency, processingCurrencyRate);
  }

  if (taxAmount) {
    return `${t('PH.WITHDRAWAL.TOOLTIP.TAX.TITLE')} ${getPrettierFormatNumber(taxAmount)} ${currency}`;
  }

  return '';
};

interface SetWithdrawalInitDataToStoreProps {
  initData: UIPayout;
  dispatch: Dispatch;
  navigate: NavigateFunction;
  payGroupId?: number;
}

export const setWithdrawalInitDataToStore = (props: SetWithdrawalInitDataToStoreProps) => {
  const {
    initData,
    dispatch,
    navigate,
    payGroupId,
  } = props;

  if (!payGroupId) {
    return;
  }

  const method = initData.methods.find(({ id }) => id === payGroupId);

  if (!method) {
    return;
  }

  selectWithdrawalMethodHandler({
    method,
    platform: initData?.cashierSettings?.platform,
    dispatch,
    navigate,
    flowType: FlowTypes.withdrawal,
  }, {} as MouseEvent);
};
