/* eslint-disable max-len */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable camelcase */
import { uniq } from 'ramda';

import {
  PayHubAdminPanelDomainMethodsEnumsPayMethodType,
  PayHubCashierContractsPaymentGroupInfo as GroupInfo,
  PayHubCashierContractsPaymentsInitPaymentGroupsCurrencyResponse as Currency,
  PayHubCashierContractsViewDataResponse,
  RenderCategory,
  PayHubCashierContractsTaxResponse,
  PayHubCashierContractsWithdrawalReverseResponse,
  PayHubDomainCashierEnumsCashierViewDataStyle,
  PayHubDomainCashierEnumsCashierViewDataType,
  PayHubCashierContractsLostProfitMerchantBonus,
  PayHubCashierContractsPaymentsInitPaymentGroupsResponse as InitSuccess,
  LostProfitInfo,
  PayHubCashierContractsTrustedContactResponse,
  MerchantUserFeeResponce,
  PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType,
  UserBonusInfo,
} from 'store/models';
import { encodeToBase64, getSymbolFromCurrency } from 'utils';

export interface Tier {
  from?: number;
  to?: number;
  percent_value?: number;
  absolute_value?: number;
}

export type GeneralInfo = {
  isLimitReached: boolean;
  generalViewData: PayHubCashierContractsViewDataResponse[] | null | undefined;
  lost_profit_merchant_bonus?: PayHubCashierContractsLostProfitMerchantBonus[] | null;
  disabledByWager?: boolean;
  userBonuses?: UserBonusInfo[] | null;
};

export type MethodsType = {
  title: string;
  isActive: boolean;
};

export interface UIPaymentGroups {
  arrangement: number;
  id: number;
  name: string;
  displayName: string;
  isPopular: boolean;
  logoUri: string;
  nativePayType: string;
  paymentType: string;
  requiredFields: unknown[];
  currencyId: number;
  currencyAlpha3: string | null;
  descriptionUrl: string;
  limits: {
    min: number;
    max: number | null | undefined;
  };
  amountRegExpFe: string;
  viewData: unknown[];
  payGroupActionType: PayHubAdminPanelDomainPayGroupsEnumsPayGroupActionType | string;
  fee: string;
  merchantUserFee?: MerchantUserFeeResponce;
  processingTimeTranslationKey?: string;
  showProcessingTime: boolean;
  isSelfTarget: boolean;
  enabled: boolean;
  restrictedUntil?: string;
  pay_method_type: PayHubAdminPanelDomainMethodsEnumsPayMethodType | string;
  last_used: boolean;
  autoSubmit: boolean;
  isCheckout: boolean;
  preSelectedMethod: boolean;
  isLostProfit: boolean;
  // eslint-disable-next-line no-use-before-define
  lostProfitInfo: LostProfitInfo | null;
  isLoading: boolean;
  showInCarousel: boolean;
  isIncludeBonus: boolean;
}

export type UIPayoutGroups = Omit<UIPaymentGroups, 'lostProfitInfo' | 'preSelectedMethod' | 'isLostProfit' | 'isIncludeBonus'>;

export type DisabledMethodsGroups = {
  disabledMethodListUntil?: UIPaymentGroups[][] | UIPayoutGroups[][];
  disabledMethodList?: UIPaymentGroups[][] | UIPayoutGroups[][];
};

type VerificationFieldData = {
  name?: string;
  dataType?: string;
};

export type VerificationData = {
  trustedContacts?: PayHubCashierContractsTrustedContactResponse[];
  card?: number;
  supportUrl?: string;
  field?: VerificationFieldData;
};
export interface UIPayment {
  // eslint-disable-next-line no-use-before-define
  methodsTypes: MethodsType[];
  isReady: boolean;
  canAutoRedirect: boolean;
  isRequestPending: boolean;
  submitPaymentReady: boolean;
  currencyId: number | null;
  currencyAlpha3: string | null;
  // methodToRedirect: UIPaymentGroups | undefined;
  cashierSettings: Pick<InitSuccess, 'cashier_settings'>['cashier_settings'];
  growthbookFeatures?: string[];
  methods: UIPaymentGroups[];
  initCarouselMethods: UIPaymentGroups[];
  // lostProfitMethods: extendedLostProfitMerchantBonus,
  // eslint-disable-next-line no-use-before-define
  viewData: PayHubCashierContractsViewDataResponse[];
  userBalance: number;
  tax?: PayHubCashierContractsTaxResponse;
  withdrawalReverse?: PayHubCashierContractsWithdrawalReverseResponse;
  merchantUrl?: string;
  merchantSuccessUrl?: string;
  merchantFailUrl?: string;
  merchantProcessingUrl?: string;
  merchantRedirectType?: string;
  userBonuses: UserBonusInfo[] | null;
}

type AdditionalCardsData = {
  card_id?: number;
};

export type UIWithdrawalCard = {
  additional_data?: AdditionalCardsData;
  label?: string;
  value?: string;
  isNew?: boolean;
};

export interface UIPayout {
  methodsTypes: MethodsType[];
  isReady: boolean;
  canAutoRedirect: boolean;
  isRequestPending: boolean;
  submitPaymentReady: boolean;
  currencyId: number | null;
  currencyAlpha3: string | null;
  cashierSettings: Pick<InitSuccess, 'cashier_settings'>['cashier_settings'];
  methods: UIPayoutGroups[];
  initCarouselMethods: UIPayoutGroups[];
  viewData: PayHubCashierContractsViewDataResponse[];
  userBalance: number;
  tax?: PayHubCashierContractsTaxResponse;
  cards?: UIWithdrawalCard[];
  selectedCard?: UIWithdrawalCard;
  accountVerifBlocked?: boolean;
  accountVerifBlockedUntil?: string | null;
  verifyNewPayoutAccount?: boolean;
  verificationData?: VerificationData;
  merchantUrl?: string;
  merchantSuccessUrl?: string;
  merchantFailUrl?: string;
  merchantProcessingUrl?: string;
  merchantRedirectType?: string;
  growthbookFeatures?: string[];
}

export type ViewDataType = PayHubCashierContractsViewDataResponse[] | null | undefined;

const getNotificationByPartialMatch = (
  searchValue: string,
  viewData: ViewDataType,
): PayHubCashierContractsViewDataResponse | null => {
  if (!Array.isArray(viewData)) {
    return null;
  }

  const limitNotification = viewData.find(({ key }) => key && key.includes(searchValue));

  if (limitNotification) {
    return limitNotification;
  }

  return null;
};

export const getIsLimitReached = (viewData: ViewDataType) => {
  const limitBlockNotification = getNotificationByPartialMatch('limit', viewData);

  if (!limitBlockNotification) {
    return false;
  }

  return !limitBlockNotification.params?.amount;
};

export const getEnabled = (
  isLimitReached: boolean,
  min: number,
  method: GroupInfo,
  viewData: ViewDataType,
  disabledByWager = false,
) => {
  if (isLimitReached) {
    return false;
  }

  const limitBlockNotification = getNotificationByPartialMatch('limit', viewData);

  if (typeof min === 'number' && min > limitBlockNotification?.params?.amount) {
    return false;
  }

  if (disabledByWager) {
    return false;
  }

  return method.enabled === undefined ? true : method.enabled;
};

export const sortByArrangement = (a: GroupInfo, b: GroupInfo): number => {
  if (!a || !b || typeof a.arrangement !== 'number' || typeof b.arrangement !== 'number') {
    return 0;
  }

  return a.arrangement > b.arrangement ? -1 : 1;
};

interface FormNotificationDataProps {
  viewData: ViewDataType;
  withdrawalReverse?: PayHubCashierContractsWithdrawalReverseResponse;
  isGeneral?: boolean;
  payGroupId?: number;
}
export const formNotificationData = (props: FormNotificationDataProps) => {
  const {
    viewData,
    withdrawalReverse,
    isGeneral,
    payGroupId,
  } = props;
  const generalCategories = [
    RenderCategory.depositMethodList,
    RenderCategory.withdrawalMethodList,
  ];
  const formCategories = [
    RenderCategory.depositForm,
    RenderCategory.withdrawalForm,
  ];
  const categories = isGeneral ? generalCategories : formCategories;
  const notificationList = [];

  if (withdrawalReverse) {
    notificationList.push({
      style: PayHubDomainCashierEnumsCashierViewDataStyle.Info,
      type: PayHubDomainCashierEnumsCashierViewDataType.Wager,
      key: encodeToBase64({
        ...withdrawalReverse,
        categories: `${categories}`,
        payGroupId,
      } as Record<string, number | string>),
      value: 'PH.INFO.WITHDRAWAL_REVERCE',
      params: {
        amount: withdrawalReverse.amount,
        currency_icon: getSymbolFromCurrency(withdrawalReverse.currency || ''),
      },
      categories: [
        RenderCategory.depositMethodList,
        RenderCategory.depositForm,
      ],
      payGroupId,
    });
  }

  if (Array.isArray(viewData)) {
    return [
      ...notificationList,
      ...viewData.map((notification, i) => ({
        ...notification,
        key: notification.key || encodeToBase64({
          ...notification,
          categories: `${categories}`,
          payGroupId,
        } as Record<string, unknown> | string),
        categories,
        payGroupId,
      }))];
  }

  return notificationList;
};

export const getCurrency = (pmMethod: GroupInfo) => {
  if (
    Array.isArray(pmMethod.currencies)
    && pmMethod.currencies[0]
  ) {
    return pmMethod.currencies[0];
  }

  return {
    currency: {
      currency_id: 0,
      name: '',
      alpha3: '',
      iso_number: '',
      precision: 0,
    },
  };
};

interface GetTax {
  tax?: PayHubCashierContractsTaxResponse;
  amount?: number;
}

type CalculateAmountWithTaxes = {
  amount: number;
  nonTaxableAmount?: number;
  taxes?: number | Tier;
  tax?: PayHubCashierContractsTaxResponse;
};

export interface CalculateAmountWithTaxesResponse {
  calculatedAmount: number;
  taxAmount: number;
}

export const getNonTaxableAmount = (props: PayHubCashierContractsTaxResponse): number => {
  const tier = props.tiers?.find(
    ({
      percent_value,
      absolute_value,
    }) => percent_value === 0 && absolute_value === 0,
  );

  return tier?.to || 0;
};

export const getTax = (props?:GetTax): Tier | undefined => {
  const {
    tax,
    amount,
  } = props || {};

  if (tax?.tiers && amount) {
    return tax.tiers.find(({ from = 0, to = 0 }) => from <= amount && amount <= to);
  }

  return undefined;
};

/*
* Node: calculateAmountWithTaxes logic
* taxes as number - simple calculation. Tax - already in correct form like 0.05 or 0.025
*
* taxes as object - calculation ин levels/tiers/ladder. Every tier should be calculated.
* For example:
* There are several tiers: [
*       {from: 0, to: 199, %: 5},
*       {from: 200, to: 499, %: 15},
*       {from: 500, to: 10000, %25}
* ]
* In case amount = 300:
*   1. calculate tax amount for first tier, for tiers[0].to
*   2. calculate tax for second tier, for (amount - for tiers[0].from)
*   3. Do not calculate tax for third tier, because of amount not hit in third tier.
*   4. after put everithing together
* */
export const calculateAmountWithTaxes = (
  props: CalculateAmountWithTaxes,
): CalculateAmountWithTaxesResponse => {
  const {
    amount,
    nonTaxableAmount = 0,
    taxes,
    tax,
  } = props;

  if (tax && typeof taxes === 'object' && amount > nonTaxableAmount && !!tax.tiers?.length) {
    const calculatedTaxObject = tax.tiers.reduce((acc, tier) => {
      const {
        from = 0,
        to = 0,
        percent_value = 0,
        absolute_value = 0,
      } = tier;

      if (from < amount) {
        if (amount > to) {
          const preparedTax = (to - from) * percent_value + absolute_value;

          return {
            calculatedAmount: acc.calculatedAmount + to - from - preparedTax,
            taxAmount: acc.taxAmount + preparedTax,
          };
        }
        if (amount <= to) {
          const preparedTax = (amount - from) * percent_value + absolute_value;

          return {
            calculatedAmount: acc.calculatedAmount + (amount - from) - preparedTax,
            taxAmount: acc.taxAmount + preparedTax,
          };
        }
      }

      return acc;
    }, {
      calculatedAmount: 0,
      taxAmount: 0,
    });

    return {
      calculatedAmount: parseFloat(calculatedTaxObject.calculatedAmount.toFixed(2)),
      taxAmount: parseFloat(calculatedTaxObject.taxAmount.toFixed(2)),
    };
  }

  return {
    calculatedAmount: amount,
    taxAmount: 0,
  };
};

interface GetAmountWithTaxes {
  isExtraTaxInfoShown: boolean;
  tax: PayHubCashierContractsTaxResponse;
  amount: number;
  calculateNonTaxableAmount: number;
  taxValue?: Tier;
}

export const getAmountWithTaxes = (props: GetAmountWithTaxes): CalculateAmountWithTaxesResponse => {
  const {
    isExtraTaxInfoShown,
    tax,
    amount,
    calculateNonTaxableAmount,
    taxValue,
  } = props;

  if (isExtraTaxInfoShown && tax) {
    return calculateAmountWithTaxes({
      amount,
      nonTaxableAmount: calculateNonTaxableAmount,
      taxes: taxValue,
      tax,
    });
  }
  return {
    calculatedAmount: Number(amount),
    taxAmount: 0,
  };
};

export const getMinLimit = (currentCurrency: Currency): number => {
  try {
    if (typeof currentCurrency.limits!.min_amount === 'number') {
      return currentCurrency.limits!.min_amount;
    }
    return 0;
  } catch (error) {
    return 0;
  }
};

// eslint-disable-next-line consistent-return
export const getMaxAmount = (currentCurrency: Currency): number | undefined => {
  try {
    if (typeof currentCurrency.limits!.max_amount === 'number') {
      return currentCurrency.limits!.max_amount;
    }
  } catch (error) {
    return undefined;
  }
};

const methodsPaymentTranslationKeys: Record<string, { translationKey: string; sort: number }> = {
  popular: {
    translationKey: 'CASHIER.POPULAR',
    sort: 0,
  },
  all: {
    translationKey: 'CASHIER.ALL',
    sort: 1,
  },
  'bank transfers': {
    translationKey: 'CASHIER.BANK_TRANSFER',
    sort: 2,
  },
  'credit and debit cards': {
    translationKey: 'CASHIER.CREDIT_DEBIT_CARD',
    sort: 3,
  },
  'e-wallets': {
    translationKey: 'CASHIER.E_WALLETS',
    sort: 4,
  },
  'prepaid & cash': {
    translationKey: 'CASHIER.CASH',
    sort: 5,
  },
  crypto: {
    translationKey: 'CASHIER.CRYPTO',
    sort: 6,
  },
  terminal: {
    translationKey: 'CASHIER.TERMINAL',
    sort: 7,
  },
  mobile: {
    translationKey: 'CASHIER.MOBILE',
    sort: 8,
  },
  'bet shops': {
    translationKey: 'CASHIER.BET_SHOPS',
    sort: 9,
  },
  'other types': {
    translationKey: 'CASHIER.OTHER',
    sort: 10,
  },
};

export const getMethodsTypes = (
  methods: Pick<UIPaymentGroups, 'nativePayType' | 'pay_method_type'>[],
): MethodsType[] => {
  const uniqMethods = uniq(
    methods.map((method) => method.pay_method_type && method.pay_method_type.toLowerCase())
      .filter((method) => method !== ''),
  );
  if (uniqMethods.length > 0) {
    const sortedMethods = uniqMethods
      .sort((curr: string, next: string) => {
        const currentMethod = methodsPaymentTranslationKeys[curr];
        const nextMethod = methodsPaymentTranslationKeys[next];
        if (currentMethod && nextMethod) {
          return currentMethod.sort - nextMethod.sort;
        }
        return -1;
      });

    return sortedMethods.map((method) => ({
      title: method,
      isActive: false,
    }));
  }
  return [];
};

export enum ExpirationDateKeys {
  CARD_EXP_Month = 'card_exp_month',
  CARD_EXP_Year = 'card_exp_year',
  Month = 'month',
  Year = 'year',
}

export const getExpirationObject = (value: string, isCheckout: boolean) => {
  try {
    const [month, year] = value.split('/');
    const yearName = isCheckout ? ExpirationDateKeys.CARD_EXP_Year : ExpirationDateKeys.Year;
    const monthName = isCheckout ? ExpirationDateKeys.CARD_EXP_Month : ExpirationDateKeys.Month;

    return {
      [monthName]: month,
      [yearName]: year,
    };
  } catch (e) {
    return {
      [ExpirationDateKeys.Month]: '',
      [ExpirationDateKeys.Year]: '',
    };
  }
};
