import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ListView, ListCell, Icon, Typography } from '@modulor/react';
import { Routes } from 'Modulor/router';
import { useCustomTranslation } from 'Modulor/hooks';
import { UIWithdrawalCard } from 'store/formatters/commonPayments';
import { EmptyCardOption } from './EmptyCardOption';
import { getCardsIcon, getCardsTypeName } from './utils';

import './Cards.scss';

interface SelectedCardProps {
  selectedCard: UIWithdrawalCard;
}

export const SelectedCard: FC<SelectedCardProps> = ({
  selectedCard = {},
}) => {
  const { t } = useCustomTranslation();

  return (
    selectedCard?.value && !selectedCard?.isNew ? (
      <div className="cards-container-wrapper">
        <div className="saved-card-label">
          <Typography variant="subhead-regular">
            {t('PH.LABEL.SAVED_CARD')}
          </Typography>
        </div>
        <Link
          className="link-box"
          to={Routes.withdrawalCardList}
        >
          <ListView className="card-box">
            <ListCell
              text={getCardsTypeName(selectedCard.label)}
              subtitle={selectedCard.label}
              image={getCardsIcon(selectedCard.label)}
              content={(
                <div className="checkbox__in-list-cell">
                  <Icon name="chevron_right" />
                </div>
              )}
            />
          </ListView>
        </Link>
      </div>
    ) : <EmptyCardOption />
  );
};
