/* eslint-disable consistent-return */
import { useState, useEffect, FC, Dispatch, SetStateAction } from 'react';
import clx from 'classnames';
import { Typography, Icon, Toast } from '@modulor/react';
import { useAppSelector } from 'store/hooks';
import { useLazyGenerateOTPQuery } from 'store/services/otpAPI';
import {
  OTPFieldNamesSelector,
  OTPFieldsDataSelector,
  OTPRequisiteSelector,
} from 'store/slices/otp';
import { amountSelector, selectedPayGroupIdSelector, userPhoneSelector } from 'store/slices/global';
import { useCustomTranslation } from 'Modulor/hooks';
import { currentDepositMethodCurrencySelector } from 'store/slices/deposit';
import './VerifyPhonePage.scss';
import { getErrorMessage } from 'utils/error';

const ONE_MIN_TIMER = 60;

interface CodeReceiverProps {
  setCodeSentStatus: Dispatch<SetStateAction<boolean>>;
  setAmountValueError: Dispatch<SetStateAction<string>>;
  sendSms: boolean;
}

export const CodeReceiver: FC<CodeReceiverProps> = ({
  setCodeSentStatus,
  setAmountValueError,
  sendSms = false,
}) => {
  const [isFirstSend, setFirstSend] = useState<boolean>(true);
  const [showTimer, setTimerOn] = useState<boolean>(false);
  const [generateCodeTrigger, response] = useLazyGenerateOTPQuery();
  const [seconds, setSeconds] = useState<number>(ONE_MIN_TIMER);
  const [generateOTPError, setGenerateOTPError] = useState<string>('');
  const requisiteFieldData = useAppSelector(OTPRequisiteSelector);
  const userSettingsContact = useAppSelector(userPhoneSelector);
  const contactValue = requisiteFieldData?.fieldValue || userSettingsContact;
  const amount = useAppSelector(amountSelector);
  const currency = useAppSelector(currentDepositMethodCurrencySelector);
  const payGroupId = useAppSelector(selectedPayGroupIdSelector);

  const filledData = useAppSelector(OTPFieldsDataSelector);
  const { requisiteFieldName } = useAppSelector(OTPFieldNamesSelector);
  const { t } = useCustomTranslation();

  const handleSendCode = () => {
    if (response.isSuccess && !response.data?.can_resend) {
      return;
    }
    if (!parseFloat(amount)) {
      setAmountValueError('PH.DEPOSIT.ERROR.INCORRECT_AMOUNT');
      return;
    }
    generateCodeTrigger({
      paygroup_id: payGroupId,
      amount: parseFloat(amount),
      currency,
      parameters: {
        [requisiteFieldName]: contactValue,
        ...filledData,
      },
    });
    if (isFirstSend) {
      setFirstSend(false);
    }
  };

  useEffect(() => {
    if (sendSms) {
      handleSendCode();
    }
  }, []);

  useEffect(() => {
    if (response?.data?.error || response?.error) {
      const error = response?.data?.error || response?.error;
      const errorObj = getErrorMessage(error);
      const { message = '' } = errorObj;

      setGenerateOTPError(message);
    }
    if (response.isSuccess && response.data.status.toLowerCase() !== 'fail') {
      setTimerOn(true);
      setCodeSentStatus(true);
    }
  }, [response]);

  useEffect(() => {
    if (seconds > 0 && showTimer) {
      const intervalId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
    if (seconds === 0) {
      setSeconds(ONE_MIN_TIMER);
      setTimerOn(false);
    }
  }, [seconds,
    showTimer]);

  const preparedSeconds = seconds.toString().padStart(2, '0');
  const countdown = seconds === ONE_MIN_TIMER ? '1:00' : `0:${preparedSeconds}`;

  return (
    <>
      {showTimer ? (
        <div className="refresh-code-block">
          <Icon name="clock_outlined" />
          <Typography
            className="get-new-code-text"
            variant="body-regular"
          >
            {t('PH.BUTTON.RECEIVE_TIME_LIMIT', {
              time: countdown,
            })}
          </Typography>
        </div>
      ) : (
        <div
          className={clx('refresh-code-block', 'send-code-button', {
            disabled: response.isSuccess && !response.data?.can_resend,
          })}
          onClick={handleSendCode}
        >
          {!isFirstSend
              && (
              <Icon
                className="icon"
                name="refresh"
              />
              )}
          <Typography
            variant="body-regular"
          >
            {t('PH.BUTTON.RECEIVE_CODE')}
          </Typography>
        </div>
      )}
      {!!generateOTPError && (
        <Toast
          variant="error"
          visible={Boolean(generateOTPError)}
          text={t(generateOTPError)}
          multiline
          rightIcon={(
            <Icon
              name="close"
              onClick={
                () => setGenerateOTPError('')
              }
            />
          )}
          onHide={() => setGenerateOTPError('')}
          duration={2500}
        />
      )}
    </>
  );
};
