/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Modal,
  PhoneField,
  Typography,
} from '@modulor/react';
import clx from 'classnames';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { brandSelector, countryCodeSelector, setUserPhone, userPhoneSelector } from 'store/slices/global';
import { OTPRequisiteSelector, setRequisiteField } from 'store/slices/otp';
import { getPattern } from 'utils';
import { useCustomTranslation, useNavigateWithSearch } from 'Modulor/hooks';
import { countryDialCode } from 'assets/countryDialCode';
import { CountrySelector } from './CountrySlector';
import { findCountryDetailsByPhoneNumber } from './utils';

import './ChangeNumber.scss';

export const ChangeNumber = () => {
  const { t } = useCustomTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithSearch();
  const requisiteFieldData = useAppSelector(OTPRequisiteSelector);
  const userSettingsContact = useAppSelector(userPhoneSelector);
  const prevNumber = requisiteFieldData?.fieldValue || userSettingsContact;
  const currentCountryCode = useAppSelector(countryCodeSelector);
  const requisiteField = useAppSelector(OTPRequisiteSelector);
  const { validationMsgKey } = requisiteField || {};
  const brand = useAppSelector(brandSelector);
  const [phone, setPhone] = useState<string>(prevNumber);
  const [isValid, setIsValid] = useState<boolean>(Boolean(prevNumber));
  const [countryCode, setLocalCountryCode] = useState(currentCountryCode);
  const [
    countryCodeSelectorVisibility, setCountryCodeSelectorVisibility,
  ] = useState<boolean>(false);
  const errorMessageKey = useMemo(() => t(validationMsgKey), [validationMsgKey]);

  const setUserPhoneToStorage = () => {
    if (requisiteFieldData) {
      dispatch(setRequisiteField({
        ...requisiteFieldData, fieldValue: phone,
      }));
    }
    dispatch(setUserPhone(phone));
    navigate(-1);
  };

  const isBrandKZ = brand?.toUpperCase() === 'KZ';

  useEffect(() => {
    const countryDetails = findCountryDetailsByPhoneNumber(prevNumber);
    if (isBrandKZ) {
      setLocalCountryCode('kz');
      return;
    }
    if (countryDetails?.iso) {
      setLocalCountryCode(countryDetails?.iso.toLowerCase());
    }
  }, [prevNumber]);

  useEffect(() => {
    if (!phone && isBrandKZ) {
      setPhone('+7');
    }
  }, [phone]);

  const onPhoneChangeHandler = (value: string) => {
    setIsValid(Boolean(value.length));
    if (requisiteField?.regexp) {
      const requisiteRegexp = getPattern(requisiteField.regexp);
      setIsValid(!!requisiteRegexp?.test(value));
    }

    const iso = findCountryDetailsByPhoneNumber(value)?.iso?.toLocaleLowerCase();
    if (iso && !isBrandKZ) {
      setLocalCountryCode(iso);
    }
    setPhone(value);
  };

  const handleChangeCountry = (lang: string) => {
    setLocalCountryCode(lang);
    const phonePrefix = countryDialCode.find((countryItem) => countryItem.iso === lang.toUpperCase());
    if (phonePrefix?.phonecode) {
      onPhoneChangeHandler(`+${phonePrefix.phonecode}`);
    }
  };

  const onClickFlag = () => {
    if (!isBrandKZ) {
      setCountryCodeSelectorVisibility(true);
    }
  };

  return (
    <section className="change-number__page">
      <div className="change-number__page__content">
        <PhoneField
          className={clx('change-number__page__content-field', {
            'change-number__page__kz': isBrandKZ,
          })}
          value={phone}
          countryCode={countryCode}
          onChange={(value) => onPhoneChangeHandler(`${value}`)}
          onFlagClick={onClickFlag}
          isVisibleFlagArrow
          error={!isValid && errorMessageKey}
          pattern={requisiteField?.regexp}
        />
        <Modal
          title="Select country"
          openModal={countryCodeSelectorVisibility}
          buttonNumber={1}
          buttons={(
            <Button
              variant="primary"
              onClick={() => setCountryCodeSelectorVisibility(false)}
            >
              {t('PH.BUTTON.DONE')}
            </Button>
                )}
        >
          <></>
          <CountrySelector
            setLocalCountryCode={handleChangeCountry}
            countryCode={countryCode}
          />
        </Modal>
        <Typography variant="caption-1-regular">
          {t('PH.DEPOSIT.TOOLTIP.VERIFICATION_CODE_SMS')}
        </Typography>
      </div>
      <div className="change-number__page__footer">
        <div className="divider" />
        <Button
          className="submit-phone-button"
          variant="primary"
          onClick={setUserPhoneToStorage}
          disabled={!isValid}
        >
          {t('PH.BUTTON.CHANGE_PHONE_NUMBER')}
        </Button>
      </div>
    </section>
  );
};
