import { FC } from 'react';
import { Button, Alert } from '@modulor/react';
import { useCustomTranslation } from 'Modulor/hooks';

interface RecurringCheckModalProps {
  isOpened: boolean;
  bodyContent: JSX.Element;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
}

export const RecurringCheckModal: FC<RecurringCheckModalProps> = (props) => {
  const {
    isOpened,
    bodyContent,
    onClose,
    onConfirm,
  } = props;
  const { t } = useCustomTranslation();
  const TITLE = t('PH.CVV.INPUT.RECURRING');
  const CONFIRM_BUTTON_LABEL = t('PH.BUTTON.CONFIRM');
  const BACK_BUTTON_LABEL = t('PH.BUTTON.CANCEL');

  return (
    <Alert
      open={isOpened}
      title={TITLE}
      content={bodyContent}
      vertical
    >
      <Button
        variant="primary"
        onClick={onConfirm}
      >
        {CONFIRM_BUTTON_LABEL}
      </Button>
      <Button
        variant="secondary"
        onClick={onClose}
      >
        {BACK_BUTTON_LABEL}
      </Button>
    </Alert>
  );
};
