import { FlatStringObject } from 'commonTypes';
import { ControllerFieldState } from 'react-hook-form/dist/types/controller';
import { FlowTypes } from 'store/constants';
import { FieldType } from 'utils';

const GENERAL_ERROR = 'PH.ERROR.FIELD_HAS_ERROR';

export const defaultErrorsMap: FlatStringObject = {
  required: 'PH.ERROR.FIELD_REQUIRED',
  min: GENERAL_ERROR,
  max: GENERAL_ERROR,
  pattern: GENERAL_ERROR,
  minLength: GENERAL_ERROR,
  maxLength: GENERAL_ERROR,
};

export const getAmountErrorKey = (flowType: FlowTypes, type?: string): string => {
  if (!type) {
    return '';
  }

  const fType = flowType.toUpperCase();

  if ([
    'required',
    'min',
    'max',
    'pattern',
    'isAmountAboveUserBalance',
  ].includes(type)) {
    switch (type) {
      case 'required': {
        return 'PH.ERROR.FIELD_REQUIRED';
      }
      case 'min': {
        return `PH.${fType}.ERROR.UNDER_MIN_LIMIT`;
      }
      case 'max': {
        return `PH.${fType}.ERROR.ABOVE_MAX_LIMIT`;
      }
      case 'pattern': {
        return `PH.${fType}.ERROR.INCORRECT_AMOUNT`;
      }
      case 'isAmountAboveUserBalance': {
        return 'CASHIER.PH2.ERROR_MESSAGE.1206';
      }

      default: {
        return `PH.${fType}.ERROR.INCORRECT_AMOUNT`;
      }
    }
  }

  return '';
};

export const getErrorKey = (
  fieldType: FieldType,
  fieldState: ControllerFieldState,
  validationMsgKey?: string,
): string => {
  const { error } = fieldState;
  if (!error?.type) {
    return '';
  }

  if (error.type === 'pattern' && validationMsgKey) {
    return validationMsgKey;
  }

  if (error.type === 'rule' && error.message) {
    return error.message;
  }

  // eslint-disable-next-line default-case
  switch (fieldType) {
    case FieldType.cardPan: {
      return 'PH.ERROR.INCORRECT_CARD_NUMBER';
    }
    case FieldType.cardCvv: {
      return 'PH.ERROR.INCORRECT_CVV';
    }
    case FieldType.expirationDate: {
      return 'PH.ERROR.INCORRECT_EXP_DATE';
    }
  }

  const keys = Object.keys(defaultErrorsMap);

  if (keys.includes(error.type)) {
    return defaultErrorsMap[error.type];
  }

  return '';
};
