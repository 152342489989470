/* eslint-disable max-len */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType,
  PayHubCashierContractsParamOptionResponse,
  PayHubCashierContractsTaxResponse,
  PayHubDemeterContractsPayoutsSelectPayoutGroupResponse,
} from 'store/models/APIModels';
import { extendedWithdrawalAPI } from 'store/services/withdrawalAPI';
import { UIPayoutGroups, UIPayout, UIWithdrawalCard, VerificationData } from '../formatters';
import { getGroupAttributes } from '../../utils';
import { DEFAULT_EMPTY_CURRENCY_ALPHA3 } from '../constants';

interface WithdrawalSlice extends UIPayout {
  currentMethod?: UIPayoutGroups;
  extendedTypeForCardParam?: PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType;
  responceByReqId?: Record<string, PayHubDemeterContractsPayoutsSelectPayoutGroupResponse>;
  requestGroupIds?: Record<string, string | null>;
  selectedBetShop?: PayHubCashierContractsParamOptionResponse;
  betShopOptions?: PayHubCashierContractsParamOptionResponse[];
}

const initialState: WithdrawalSlice = {
  methodsTypes: [],
  isReady: false,
  canAutoRedirect: false,
  isRequestPending: false,
  submitPaymentReady: false,
  currencyId: null,
  currencyAlpha3: null,
  cashierSettings: {},
  methods: [],
  initCarouselMethods: [],
  growthbookFeatures: [],
  viewData: [],
  cards: [],
  selectedCard: {},
  accountVerifBlocked: false,
  accountVerifBlockedUntil: null,
  verifyNewPayoutAccount: false,
  tax: {},
  verificationData: {},
  userBalance: 0,
  responceByReqId: {},
  requestGroupIds: {},
  selectedBetShop: {},
  betShopOptions: [],
};

type accountVerifBlocked = {
  accountVerifBlocked: boolean;
  accountVerifBlockedUntil: string | null;
};

export const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState,
  reducers: {
    setCurrentMethod(state, action: PayloadAction<UIPayoutGroups>) {
      return {
        ...state,
        currentMethod: action.payload,
      };
    },
    setWithdrawalCardsList(state, action: PayloadAction<UIWithdrawalCard[]>) {
      return {
        ...state,
        cards: action.payload,
      };
    },
    setSelectedWithdrawalCard: (state, action: PayloadAction<UIWithdrawalCard>) => ({
      ...state,
      selectedCard: action.payload,
    }),
    disableAccountVerification: (state, action: PayloadAction<accountVerifBlocked>) => ({
      ...state,
      accountVerifBlocked: action.payload.accountVerifBlocked,
      accountVerifBlockedUntil: action.payload.accountVerifBlockedUntil,
    }),
    setAccVarificationData: (state, action: PayloadAction<VerificationData>) => ({
      ...state,
      verificationData: action.payload,
    }),
    setWithdrawalTax: (state, action: PayloadAction<PayHubCashierContractsTaxResponse | undefined>) => ({
      ...state,
      tax: action.payload,
    }),
    cleanCurrentMethod: (state) => ({
      ...state,
      currentMethod: undefined,
    }),
    setVerifyNewPayoutAccount: (state, action: PayloadAction<boolean>) => ({
      ...state,
      verifyNewPayoutAccount: action.payload,
    }),
    setExtendedTypeForCardParam: (
      state,
      action: PayloadAction<PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType>,
    ) => ({
      ...state,
      extendedTypeForCardParam: action.payload,
    }),
    setBetShopSelected: (state, action: PayloadAction<PayHubCashierContractsParamOptionResponse>) => ({
      ...state,
      selectedBetShop: action.payload,
    }),
    setBetShopOptions: (state, action: PayloadAction<PayHubCashierContractsParamOptionResponse[]>) => ({
      ...state,
      betShopOptions: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(extendedWithdrawalAPI.endpoints.getWithdrawalMethodInfo.matchPending, (state, action) => {
      const { payGroupId } = action.meta.arg.originalArgs;
      if (state.requestGroupIds) {
        state.requestGroupIds[payGroupId.toString()] = action.meta.requestId;
      }
    });
    builder.addMatcher(extendedWithdrawalAPI.endpoints.getWithdrawalMethodInfo.matchFulfilled, (state, action) => {
      const { payGroupId } = action.meta.arg.originalArgs;
      const requestId = state.requestGroupIds ? state.requestGroupIds[payGroupId] : null;
      if (requestId === action.meta.requestId && state.responceByReqId) {
        const response = action.payload;
        state.responceByReqId[action.meta.requestId] = response;
      }
    });
  },
});

export const withdrawalUserBalanceSelector = ({ withdrawal }: { withdrawal: WithdrawalSlice }) => withdrawal.userBalance;
export const withdrawalCardsSelector = ({ withdrawal }: { withdrawal: WithdrawalSlice }) => withdrawal.cards || [];
export const withdrawalSelectedCardSelector = ({ withdrawal }: { withdrawal: WithdrawalSlice }) => withdrawal.selectedCard || {};
export const withdrawalTaxSelector = (state: { withdrawal?: WithdrawalSlice }) => {
  const { withdrawal } = state;
  if (withdrawal) {
    return withdrawal.tax || {};
  }
  return {};
};
export const isAccVerifBlockedSelector = ({ withdrawal }: { withdrawal: UIPayout }) => withdrawal.accountVerifBlocked;
export const accountVerifBlockedUntilSelector = ({ withdrawal }: { withdrawal: UIPayout }) => withdrawal.accountVerifBlockedUntil;
export const userVerificationDataSelector = ({ withdrawal }: { withdrawal: UIPayout }) => withdrawal.verificationData;
export const verifyNewPayoutAccountSelector = ({ withdrawal }: { withdrawal: UIPayout }) => withdrawal.verifyNewPayoutAccount;

export const currentWithdrawalMethodSelector = (state: { withdrawal?: WithdrawalSlice }) => state
  .withdrawal?.currentMethod;

export const methodNameSelector = (state: { withdrawal?: WithdrawalSlice }) => state
  .withdrawal?.currentMethod?.displayName || '';

export const isSelfTargetSelector = (state: { withdrawal?: WithdrawalSlice }) => Boolean(
  state?.withdrawal?.currentMethod?.isSelfTarget,
);

export const descriptionUrlSelector = (state: { withdrawal?: WithdrawalSlice }) => state
  ?.withdrawal?.currentMethod?.descriptionUrl;

export const extendedTypeForCardParamSelector = (state: { withdrawal?: WithdrawalSlice }) => state
  ?.withdrawal?.extendedTypeForCardParam;

export const requestIdSelector = (deposit: WithdrawalSlice, payGroupId: number) => deposit?.requestGroupIds?.[payGroupId];

export const methodInfoSelector = ({ withdrawal }: {
    withdrawal: WithdrawalSlice;
  }, payGroupId: number) => {
  const requestId = requestIdSelector(withdrawal, payGroupId);
  return typeof requestId === 'string' && withdrawal.responceByReqId ? withdrawal.responceByReqId[requestId] || {} : {};
};

export const currentWithdrawalMethodCurrencySelector = (state: { withdrawal?: WithdrawalSlice }) => state
  ?.withdrawal?.currentMethod?.currencyAlpha3 || getGroupAttributes().currencyAttr || DEFAULT_EMPTY_CURRENCY_ALPHA3;

export const currentBetShopSelector = (state: { withdrawal?: WithdrawalSlice }) => state
  .withdrawal?.selectedBetShop;

export const betShopOptionsSelector = (state: { withdrawal?: WithdrawalSlice }) => state
  .withdrawal?.betShopOptions;

export const {
  setWithdrawalTax,
  setCurrentMethod,
  setWithdrawalCardsList,
  setSelectedWithdrawalCard,
  disableAccountVerification,
  setAccVarificationData,
  cleanCurrentMethod,
  setVerifyNewPayoutAccount,
  setExtendedTypeForCardParam,
  setBetShopSelected,
  setBetShopOptions,
} = withdrawalSlice.actions;

export default withdrawalSlice.reducer;
