import { FC, useEffect, useRef, useState } from 'react';
import { Button, Icon, Tooltip, Typography } from '@modulor/react';
import clx from 'classnames';
import { ProcessingCurrency } from 'store/models';
import { Amount, getConversionText } from '../Deposit/utils';
import { useCustomTranslation, useOnClickOutside } from '../hooks';

import './ConversionBlock.scss';
import { displayTooltip } from '../../utils';

interface ConversionBlockProps {
  amount: Amount;
  methodName: string;
  processingCurrency?: ProcessingCurrency;
  processingCurrencyRate?: number;
}

export const ConversionBlock: FC<ConversionBlockProps> = (props) => {
  const {
    amount,
    processingCurrency,
    processingCurrencyRate,
    methodName,
  } = props;
  const { t } = useCustomTranslation();
  const hintRef = useRef<HTMLDivElement | null>(null);
  const [show, showHint] = useState<boolean>(false);

  useEffect(() => {
    displayTooltip(false);
  }, []);

  const toggleTooltip = () => {
    if (!show) {
      showHint(true);
      displayTooltip(true);
      return;
    }

    displayTooltip(false);
    showHint(false);
  };

  useOnClickOutside(hintRef, () => showHint(false));

  if (!processingCurrency || !processingCurrencyRate) {
    return null;
  }

  const convertedAmountWithSymbol = getConversionText(amount, processingCurrency, processingCurrencyRate);
  const alpha3 = processingCurrency?.alpha3 || '';
  const message = t('PH.CONVERSION_BLOCK_ABOUT', {
    currency: alpha3,
  });
  const infoMessage = t('PH.CONVERSION_BLOCK_INFO', {
    method_name: methodName,
    currency: alpha3,
  });

  return (
    <div className="conversion-block__container">
      <div
        className={clx('conversion-block__content', {
          'is-opened': show,
        })}
      >
        <div data-testid="conversion-block-text">
          <Typography variant="subhead-regular">{message}</Typography>
          <span
            ref={hintRef}
          >
            <Tooltip
              show={show}
              className="info-tooltip"
              heading=""
              text={infoMessage}
              button={(
                <Button
                  variant="primary"
                  className="info-tooltip-button"
                  onClick={toggleTooltip}
                  label={t('PH.BUTTON.ACCEPT')}
                />
                  )}
            >
              {[<Icon
                key="info-icon"
                data-testid="conversion-block-info"
                onClick={toggleTooltip}
                className="info-icon"
                name="info_circle_outlined"
              />]}
            </Tooltip>
          </span>
        </div>
        <div
          data-testid="conversion-block-value"
          className="tax-block-value"
        >
          <Typography variant="subhead-regular">{convertedAmountWithSymbol}</Typography>
        </div>
      </div>
    </div>
  );
};
