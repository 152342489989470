import { useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { Button, Icon, TextField, Toast, Typography } from '@modulor/react';
import { FlowTypes } from 'store/constants';
import { CustomServerError, useLazySubmitDepositQuery } from 'store/services/depositAPI';
import { amountSelector, userPhoneSelector } from 'store/slices/global';
import { currentDepositMethodCurrencySelector } from 'store/slices/deposit';
import {
  OTPFieldNamesSelector,
  OTPRequisiteSelector,
  OTPFieldsDataSelector,
} from 'store/slices/otp';
import { SubmitFormWrapper } from 'Modulor/components/SubmitForm/SubmitFormWrapper';
import {
  useAbortSubmitControllerRef,
  useCustomTranslation,
  useFormContainerHook,
  useFrameChangeHeight,
  useGetPayGroupId, useNavigateWithSearch,
} from 'Modulor/hooks';
import { formRequestBody } from '../payUtils';
import { CodeReceiver } from './CodeReceiver';
import { formContentValue } from './utils';
import { Routes } from '../router';

import './VerifyPhonePage.scss';

export const VerifyPhonePage = () => {
  const [code, setCode] = useState<string>('');
  const [isCodeSent, setCodeSentStatus] = useState<boolean>(false);
  const [submitGeneralError, setSubmitGeneralError] = useState<string>('');
  const [amountValueError, setAmountValueError] = useState<string>('');

  const navigate = useNavigateWithSearch();

  const { t } = useCustomTranslation();
  const requisiteFieldData = useAppSelector(OTPRequisiteSelector);
  const amount = useAppSelector(amountSelector);
  const currency = useAppSelector(currentDepositMethodCurrencySelector);
  const filledData = useAppSelector(OTPFieldsDataSelector);
  const { requisiteFieldName, codeFieldName } = useAppSelector(OTPFieldNamesSelector);
  const userSettingsContact = useAppSelector(userPhoneSelector);
  const contactValue = requisiteFieldData?.fieldValue || userSettingsContact;
  const payGroupId = useGetPayGroupId();
  const formattedContactValue = formContentValue(contactValue);
  const [submitDepositTrigger, submitDepositResult] = useLazySubmitDepositQuery();
  const abortControllerRef = useAbortSubmitControllerRef();
  const { error, data, isFetching } = submitDepositResult;

  const isChangeDisabled = !!requisiteFieldData?.isDisabled;
  const redirectToChangeNumber = () => navigate(Routes.changeNumber);
  useFrameChangeHeight(true);

  useFormContainerHook({
    error: error as CustomServerError,
    setSubmitGeneralError,
  });

  const onSubmit = () => {
    if (!parseFloat(amount)) {
      setAmountValueError('PH.DEPOSIT.ERROR.INCORRECT_AMOUNT');
      return;
    }

    if (requisiteFieldData?.name === 'code' && requisiteFieldData?.regexp?.length && code.length) {
      const regexpString = (requisiteFieldData.regexp.startsWith('/') && requisiteFieldData.regexp.endsWith('/'))
        ? requisiteFieldData.regexp.slice(1, -1) : requisiteFieldData.regexp;
      const regexp = new RegExp(regexpString);
      if (!regexp.test(code)) {
        setSubmitGeneralError('PH.DEPOSIT.ERROR.INCORRECT_CODE');
        return;
      }
    }

    const requestBody = formRequestBody({
      fields: {
        ...filledData,
        [requisiteFieldName]: contactValue,
        [codeFieldName]: code,
      },
      currency,
      amount,
    });

    abortControllerRef.current = new AbortController();
    submitDepositTrigger({
      payGroupId,
      requestBody,
      signal: abortControllerRef.current.signal,
    });
  };

  const handleCodeChange = (value: string) => {
    setCode(value);
  };

  const pendingSubmit = submitDepositResult.isFetching;
  const isSubmitDisabled = !isCodeSent || pendingSubmit || code.length < 2;

  if (!submitGeneralError && !!data) {
    return (
      <SubmitFormWrapper
        paymentType={FlowTypes.deposit}
        redirectForm={data}
      />
    );
  }

  const dropErrors = () => {
    setSubmitGeneralError('');
  };

  return (
    <div className="verification-form">
      {!!submitGeneralError && (
        <Toast
          variant="error"
          visible={Boolean(submitGeneralError)}
          text={t(submitGeneralError)}
          multiline
          rightIcon={(
            <Icon
              name="close"
              onClick={
                () => setSubmitGeneralError('')
              }
            />
          )}
          onHide={() => setSubmitGeneralError('')}
          duration={2500}
        />
      )}
      <Typography
        className="contact-value"
        variant="title-1-semibold"
      >
        { formattedContactValue }
      </Typography>
      <Typography
        className="logical-block method-kind-info"
        variant="body-regular"
      >
        {t('PH.DEPOSIT.TOOLTIP.VERIFICATION_CODE_SMS')}
      </Typography>
      {!isChangeDisabled
            && (
            <div
              className="change-phone-button"
              onClick={redirectToChangeNumber}
            >
              <Typography
                variant="body-regular"
                style={{
                  color: 'var(--text-link)',
                }}
              >
                {t('PH.BUTTON.CHANGE_PHONE_NUMBER')}
              </Typography>
            </div>
            )}
      <div className="code-fields">
        <TextField
          value={code}
          type="text"
          inputMode="numeric"
          placeholder={t('CASHIER.PH2.ENTER_OTP')}
          onChange={(e) => {
            const value = (e as string).replace(/[^0-9]/g, '');
            handleCodeChange(value);
          }}
        />
      </div>
      <div className="logical-block">
        <CodeReceiver
          sendSms={isChangeDisabled}
          setCodeSentStatus={setCodeSentStatus}
          setAmountValueError={setAmountValueError}
        />
      </div>
      <div className="verification-form__footer">
        <Button
          onClick={onSubmit}
          loading={isFetching}
          disabled={isSubmitDisabled}
        >
          {t('PH.BUTTON.CONFIRM')}
        </Button>
      </div>
      {amountValueError && (
        <Toast
          variant="error"
          visible={Boolean(amountValueError)}
          text={t(amountValueError)}
          multiline
          rightIcon={(
            <Icon
              name="close"
              onClick={dropErrors}
            />
              )}
          onHide={dropErrors}
          duration={2500}
        />
      )}
    </div>
  );
};
