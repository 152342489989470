/* eslint-disable camelcase */
import { PAGE_STATUS } from 'utils/constants';
import IntegrationCommunication from '../../services/integration-communication';
import { FlowTypes } from '../../store/constants';

const STATUSES_WITH_CLOSE_MSG = [
  PAGE_STATUS.success,
  PAGE_STATUS.processing,
];

export const hasCloseCashierMsg = (isInPopup: boolean, status: PAGE_STATUS) => isInPopup && (
  STATUSES_WITH_CLOSE_MSG.includes(status));

export const closePopUp = (isError: boolean): void => {
  if (isError) {
    IntegrationCommunication.sendMessage({
      id: 'reOpenCashier',
    });
    return;
  }

  IntegrationCommunication.sendMessage({
    id: 'reInitCashier',
  });
  IntegrationCommunication.sendMessage({
    id: 'closeCashier',
  });
};

interface ReturnUrlFormer {
  success_url?: string;
  processing_url?: string;
  fail_url?: string;
  flowType: FlowTypes;
  decodedUrl: string | boolean;
  merchant_url?: string | null;
}

export const returnUrlFormer = (props: ReturnUrlFormer) => (status: PAGE_STATUS): string => {
  const {
    success_url,
    processing_url,
    fail_url,
    flowType,
    decodedUrl,
    merchant_url = '',
  } = props;
  const parsedDecodedUrl = typeof decodedUrl === 'string' ? decodedUrl : '/';
  const decodedUrlResult = parsedDecodedUrl === '/' && merchant_url ? merchant_url : parsedDecodedUrl;

  if (flowType === FlowTypes.deposit) {
    switch (status) {
      case PAGE_STATUS.success: {
        return success_url || decodedUrlResult;
      }

      case PAGE_STATUS.processing: {
        return processing_url || decodedUrlResult;
      }

      case PAGE_STATUS.error: {
        return fail_url || decodedUrlResult;
      }

      default: {
        return success_url || decodedUrlResult;
      }
    }
  }

  return decodedUrlResult;
};
