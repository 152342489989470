import { Link } from 'react-router-dom';
import { ListView, ListCell, Icon } from '@modulor/react';
import { Routes } from 'Modulor/router';
import { useCustomTranslation } from 'Modulor/hooks';

import './Cards.scss';

export const EmptyCardOption = () => {
  const { t } = useCustomTranslation();

  return (
    <div className="cards-container-wrapper">
      <Link
        className="link-box"
        to={Routes.withdrawalCardList}
      >
        <ListView className="card-box">
          <ListCell
            text={t('PH.DEPOSIT.PAY_NEW_CARD')}
            image={<Icon name="credit_card_outlined" />}
            content={(
              <div className="checkbox__in-list-cell">
                <Icon name="chevron_right" />
              </div>
            )}
          />
        </ListView>
      </Link>
    </div>
  );
};
