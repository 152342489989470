import clx from 'classnames';

const Controls = (props: { setMethod: (method: string) => void; isOpen: boolean }) => {
  const { setMethod, isOpen } = props;

  return (
    <div
      className={clx('menu-box', {
        'menu-box__open': isOpen,
      })}
    >
      <button
        onClick={() => setMethod('deposit')}
        className="menu-item"
      >
        set Deposit
      </button>
      <button
        onClick={() => setMethod('withdrawal')}
        className="menu-item"
      >
        set Withdrawal
      </button>
      <button
        onClick={() => setMethod('checkout')}
        className="menu-item"
      >
        set Checkout
      </button>
      <button
        onClick={() => setMethod('success-deposit')}
        className="menu-item"
      >
        set Success dep
      </button>
      <button
        onClick={() => setMethod('error-deposit')}
        className="menu-item"
      >
        set Error dep
      </button>
      <ul className="messages" />
    </div>
  );
};

export default Controls;
