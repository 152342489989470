import { FC, useState } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { TextField } from '@modulor/react';
import { FieldType } from 'utils';
import { useCustomTranslation } from 'Modulor/hooks';
import { CustomRegExp, CustomValidationKeys } from 'store/models/paymentProcessModels';
import { getErrorKey } from '../fieldErrors';
import { validateExpiration } from '../validation';
import { allowOnlyNumericValue } from './utils';

interface ExpirationDateFieldProps {
  name: string;
  control: Control;
  customRegExp?: CustomRegExp;
  validationKeys?: CustomValidationKeys;
}

export const ExpirationDateField:FC<ExpirationDateFieldProps> = (props) => {
  const {
    name,
    control,
    customRegExp,
    validationKeys,
  } = props;
  const { t } = useCustomTranslation();
  const [expValue, setExpValue] = useState('');

  const expriyFormat = (value: string) => {
    const expdate = value;
    return expdate.replace(/\//g, '').substring(0, 2)
      + (expdate.length > 2 ? '/' : '')
      + expdate.replace(/\//g, '').substring(2, 4);
  };

  const handleOnChange = (value: string, field: FieldValues) => {
    field.onChange(expriyFormat(value));
    setExpValue(value);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: {
          rule: (value: string) => validateExpiration(expriyFormat(value), customRegExp, validationKeys),
        },
        required: true,
      }}
      render={({ field, fieldState }: FieldValues) => {
        const errorMessageKey = getErrorKey(FieldType.expirationDate, fieldState);
        const errorMessageText = fieldState.error?.message;
        const errorMessage = t(errorMessageKey || errorMessageText);
        const labelText = t('CASHIER.EXP_DATE_FORMAT');

        return (
          <div>
            <TextField
              {...field}
              onChange={(value: string) => handleOnChange(allowOnlyNumericValue(value), field)}
              value={expriyFormat(field.value || expValue)}
              maxLength={5}
              error={errorMessage}
              label={labelText}
            />
          </div>
        );
      }}
    />
  );
};
