import { FC } from 'react';
import { Typography } from '@modulor/react';
import { useCustomTranslation } from '../hooks';
import './ProcessingTime.scss';

interface ProcessingTimeProps {
  message?: string;
}

export const ProcessingTime: FC<ProcessingTimeProps> = (props) => {
  const { message } = props;
  const { t } = useCustomTranslation();

  if (!message) {
    return null;
  }

  return (
    <div className="processing-time-container">
      <Typography variant="subhead-regular">{t('PH.PROCESSING_TIME')}</Typography>
      <Typography variant="subhead-regular">{message}</Typography>
    </div>
  );
};
