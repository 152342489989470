/* eslint-disable @typescript-eslint/no-empty-function */
import { FC } from 'react';
import { Image, ListCell, ListView, Typography } from '@modulor/react';
import { useCustomTranslation } from 'Modulor/hooks';
import { UIPayoutGroups } from 'store/formatters';
import { useAppSelector } from 'store/hooks';
import { logoSizesSelector } from 'store/slices/global';
import {
  formMethodSubTitle,
  updateLogoUrlViaTheme,
  getRestrictedUntilText,
} from 'utils';

interface MethodsListProps {
    list?: UIPayoutGroups[];
    initialTheme: string;
}

export const WithdrawalMethodsListDisabled: FC<MethodsListProps> = (props: MethodsListProps) => {
  const {
    list,
    initialTheme,
  } = props;
  const { t } = useCustomTranslation();
  const logoSizes = useAppSelector(logoSizesSelector);
  const disableMethodsStyles: { [param: string]: string } = {
    '--list-cell-left-title': 'var(--text-disable)',
    '--list-cell-left-subtitle': 'var(--text-disable)',
  };

  if (!list || !list.length) {
    return null;
  }
  return (
    <ListView
      key={JSON.stringify(list)}
      divider
    >
      {list.map((m) => (
        <ListCell
          className="withdrawal-methods__item withdrawal-methods__item__disabled"
          key={m.id}
          text={t(m.displayName) || ''}
          style={disableMethodsStyles}
          subtitle={formMethodSubTitle({
            t,
            processingTime: m.processingTimeTranslationKey,
            fee: m.merchantUserFee || {},
          })}
          onClick={() => {}}
          multiline
          leftImageSize={logoSizes.width}
          image={(
            <Image
              className="disabled"
              dataTestId="method-icon-disabled"
              width={`${logoSizes.width}`}
              alt={m.displayName}
              src={updateLogoUrlViaTheme(m.logoUri, initialTheme)}
              style={{
                height: logoSizes.height,
                width: logoSizes.width,
              }}
            />
                )}
          content={(
            <Typography
              variant="subhead-regular"
              dataTestId="restricted-time"
            >
              <span
                data-translation-key={getRestrictedUntilText(t, m.restrictedUntil).key}
              >
                {
                                getRestrictedUntilText(t, m.restrictedUntil).message
                              }
              </span>
            </Typography>
                )}
        />
      ))}
    </ListView>
  );
};
