import { FC, Dispatch, SetStateAction, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  Button,
  Alert,
} from '@modulor/react';
import { removeCard, selectedCardSelector, setSelectedCard } from 'store/slices/userCard';
import { useDeleteUserCardMutation } from 'store/services/checkoutAPI';
import { useCustomTranslation, useNavigateWithSearch } from 'Modulor/hooks';

interface DeleteModalProps {
  isOpened: boolean;
  toggleModal: Dispatch<SetStateAction<boolean>>;
  selectedCardId: number | null | undefined;
}

export const DeleteModal: FC<DeleteModalProps> = (props) => {
  const { isOpened, toggleModal, selectedCardId } = props;
  const dispatch = useAppDispatch();
  const { t } = useCustomTranslation();
  const TITLE = t('PH.POPUP.DELETE_CARD.TITLE');
  const CONTENT = t('PH.POPUP.DELETE_CARD.BODY');
  const CONFIRM_BUTTON_LABEL = t('PH.BUTTON.DELETE');
  const BACK_BUTTON_LABEL = t('PH.BUTTON.CANCEL');
  const [trigger, response] = useDeleteUserCardMutation();
  const { cardsList = [] } = useAppSelector(selectedCardSelector);
  const navigate = useNavigateWithSearch();
  const { isLoading } = response;

  useEffect(() => {
    if (!cardsList.length) {
      navigate(-1);
    }
  }, [cardsList]);

  const onSubmit = () => {
    if (selectedCardId) {
      trigger(selectedCardId).then(() => {
        dispatch(setSelectedCard({
          isNew: true,
        }));
        dispatch(removeCard(selectedCardId));
      }).finally(() => {
        toggleModal(false);
      });
    }
  };

  return (
    <Alert
      open={isOpened}
      title={TITLE}
      content={CONTENT}
      vertical
    >
      <Button
        variant="primary"
        disabled={isLoading}
        loading={isLoading}
        onClick={() => {
          onSubmit();
        }}
      >
        {CONFIRM_BUTTON_LABEL}
      </Button>
      <Button
        variant="secondary"
        onClick={() => toggleModal(false)}
      >
        {BACK_BUTTON_LABEL}
      </Button>
    </Alert>
  );
};
