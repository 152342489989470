import { TFunction } from 'commonTypes';
import { MerchantUserFeeResponce } from 'store/models';

export const getMerchantUserFeeTranslation = (fee: MerchantUserFeeResponce, t: TFunction) => {
  const percentageTranslation = fee?.percentage ? t('PH.FEE.MESSAGE', {
    percentage: `${fee.percentage}`,
  }) : '';
  const fixedFee = fee?.fix ? ` + ${fee.fix}` : '';

  return `${percentageTranslation}${fixedFee}`;
};
