/* eslint-disable max-len */
/* eslint-disable implicit-arrow-linebreak */
import { omit } from 'ramda';
import { setAmount } from 'store/slices/global';
import { Dispatch } from '@reduxjs/toolkit';
import { Routes } from 'Modulor/router';
import {
  Parameter,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes,
  PayHubCashierContractsTrustedContactResponse as TrustedContacts,
} from 'store/models';
import { FieldValues } from 'react-hook-form';
import { NavigateFunction } from 'react-router-dom';
import { countryDialCode } from 'assets/countryDialCode';
import {
  SelectActionWithdrawalMethodProps,
  prepareFieldsWithCustomParameters,
  setNullToNotRequiredFields,
} from 'utils';
import { UIWithdrawalCard } from 'store/formatters/commonPayments';
import { setCurrentMethod, setAccVarificationData } from 'store/slices/withdrawal';
import { redirectToSelectedMethod } from '../routerUtils';
import {
  setOTPFields,
  setOTPRequiredFields,
  setRequisiteField,
  setOTPFieldNames,
  setOTPFieldsData,
  setOTPSubmitFieldsData,
} from '../../store/slices/otp';
import { DynamicTypeObject, FlatStringObject } from '../../commonTypes';

export const selectActionMethod = (props: SelectActionWithdrawalMethodProps): void => {
  const {
    method,
    navigate,
    dispatch,
    flowType,
  } = props;
  redirectToSelectedMethod(method.id, navigate, flowType);
  dispatch(setCurrentMethod(method));
};

export const isCardPanSelectTypeField = (param: Parameter) =>
  [
    `${ExtendedTypes.CardPan}`,
    `${ExtendedTypes.CreditCard}`,
  ].includes(`${param.extendedType}`) && param.type === 'select';

export type FormConditions = {
  payoutAccountVerifBlocked?: boolean;
};

export const isBetshopCheckboxField = (param: Parameter) => param.type === 'check' && param.extendedType === ExtendedTypes.BetShop;

const filterFieldsForRender = (fields: FieldValues = []) => prepareFieldsWithCustomParameters(
  fields.filter((param: Parameter) => !isBetshopCheckboxField(param) && !isCardPanSelectTypeField(param)),
  false,
);

export const prepareFieldsForRender = (
  fields: FieldValues,
  { payoutAccountVerifBlocked }: FormConditions,
  cardParam: Parameter | undefined,
) => {
  if (payoutAccountVerifBlocked) {
    const preparedFields = fields.map((field: Parameter) => ({
      ...field,
      isDisabled: field.type === 'select',
    }));
    return filterFieldsForRender(preparedFields);
  }

  return !!cardParam && !cardParam?.options?.length
    ? prepareFieldsWithCustomParameters(fields as Parameter[], false)
    : filterFieldsForRender(fields);
};

export const prepareFieldsDataForSubmit = (
  fields: FieldValues,
  requiredFields: Parameter[],
  selectedCard: UIWithdrawalCard,
  cardParam: Parameter | undefined,
) => {
  const preparedFields: DynamicTypeObject = setNullToNotRequiredFields(requiredFields, fields);
  if (selectedCard.value && cardParam?.name) {
    return ({
      ...preparedFields,
      [cardParam?.name]: selectedCard.value.replace(/\s/gi, ''),
    });
  }

  return preparedFields;
};

export const isIncorrectCode = (status?: boolean): boolean => status === false;

export const getBlockedDurationValue = (timeString: string): string => {
  const [hours, minutes, seconds] = timeString.split(':');
  const totalMinutes = +hours * 60 + (+minutes + 1) + +seconds / 60;
  const currentDate = new Date();
  return new Date(currentDate.getTime() + totalMinutes * 60000).toISOString();
};

const CREDIT_CARD_TYPES = [
  ExtendedTypes.CardPan,
  ExtendedTypes.CreditCard,
];

interface RedirectForVerificationHookProps {
  requiredFields: Parameter[];
  trustedContacts?: TrustedContacts[];
  fields: FieldValues;
  supportUrl?: string;
  payoutAccountVerifBlocked: boolean;
  allowNewPayoutAccount?: boolean;
  hasNeededVerifCardsOptionsCount: boolean;
  dispatch: Dispatch;
  navigate: NavigateFunction;
}

export const handleRedirectForVerification = (props: RedirectForVerificationHookProps): void => {
  const {
    requiredFields,
    trustedContacts,
    fields,
    supportUrl,
    payoutAccountVerifBlocked,
    allowNewPayoutAccount,
    hasNeededVerifCardsOptionsCount,
    dispatch,
    navigate,
  } = props;

  const hasVerifiedParams = requiredFields.some((field) => field.verifyNewPayoutAccount);
  // eslint-disable-next-line max-len
  const fieldTypes = requiredFields.find((field) => field.extendedType && CREDIT_CARD_TYPES.includes(field.extendedType));
  const shouldRedirectToSupportPage = (!allowNewPayoutAccount && hasVerifiedParams) || !hasNeededVerifCardsOptionsCount;

  dispatch(setAccVarificationData({
    trustedContacts,
    card: fields.card,
    field: {
      name: fieldTypes?.name,
      dataType: fieldTypes?.dataType,
    },
    supportUrl,
  }));

  if (shouldRedirectToSupportPage || payoutAccountVerifBlocked) {
    navigate(Routes.accountManagementBlockedPage);
    return;
  }
  navigate(Routes.withdrawalVerifyAccount);
  dispatch(setAmount(fields.amount));
};

interface RedirectToOTP {
  isOTPEnabled: boolean;
  requiredFields: Parameter[];
  fields: FlatStringObject;
  dispatch: Dispatch;
}

export type OTPFields = {
  otpField?: Parameter;
  codeField?: Parameter;
};

export const handleRedirectToOTP = (props: RedirectToOTP): void => {
  const {
    isOTPEnabled,
    requiredFields,
    fields,
    dispatch,
  } = props;

  if (!isOTPEnabled) {
    return;
  }
  const fieldNames: OTPFields = {};
  const otpFieldData: FlatStringObject = {};
  const submitFieldData: FlatStringObject = {};
  const otpFields = requiredFields.filter((field) => field.isOtpRequisites);
  const submitFields = requiredFields.filter((field) => field.isRequired && !field.isOtpRequisites);
  const otpFieldsLastIndex = otpFields.length - 1;
  const submitFieldsLastIndex = submitFields.length - 1;

  // temporary last field with isOtpRequisites:true is otpField
  if (otpFieldsLastIndex !== -1) {
    fieldNames.otpField = otpFields[otpFieldsLastIndex];
  }

  // temporary last field with isOtpRequisites:false & isRequired - is codeField
  if (submitFieldsLastIndex !== -1) {
    fieldNames.codeField = submitFields[submitFieldsLastIndex];
  }

  requiredFields.forEach((field) => {
    const { name } = field;
    const value = fields[name];
    if (field.isOtpRequisites && name !== fieldNames.otpField?.name) {
      otpFieldData[name] = value;
    } else if (field.isRequired && !field.isOtpRequisites && name !== fieldNames.codeField?.name) {
      submitFieldData[name] = value;
    }
  });

  if (fieldNames.otpField) {
    dispatch(setRequisiteField({
      ...fieldNames.otpField, fieldValue: fields[fieldNames.otpField.name || ''],
    }));
  }

  dispatch(setOTPRequiredFields(requiredFields));
  dispatch(setOTPFields(omit(['amount'], fields)));
  dispatch(setOTPFieldsData(otpFieldData));
  dispatch(setOTPSubmitFieldsData(submitFieldData));
  dispatch(setOTPFieldNames({
    requisiteFieldName: fieldNames.otpField?.name || '',
    codeFieldName: fieldNames.codeField?.name || '',
  }));
};

export const findCountryDetailsByPhoneNumber = (phoneNumber: string) => countryDialCode.find((countryItem) => phoneNumber.startsWith(`+${countryItem.phonecode}`)
    || phoneNumber.startsWith(`00${countryItem.phonecode}`));

export const getFormattedPhone = (value: string): string => {
  if (!value) return '';
  return value.startsWith('+') ? value : `+${value}`;
};

export const formContentValue = (value: string): string => {
  try {
    const formattedValue = getFormattedPhone(value);
    const countryDetails = findCountryDetailsByPhoneNumber(formattedValue);
    const phoneCode = countryDetails?.phonecode;
    const phone = phoneCode ? formattedValue.replace(`+${phoneCode}`, '') : formattedValue;
    const res = [`+${phoneCode}`];

    for (let i = 0; i < phone.length; i += 3) {
      res.push(phone.substring(i, i + 3));
    }

    return res.join(' ');

    // const firstPosition = 5;
    // const secondPosition = value.length - 2;
    // const fillerLength = secondPosition - firstPosition;
    // const filler = new Array(fillerLength).fill('*');
    //
    // return `${value.substring(0, firstPosition)}${filler.join('')}${value.substring(secondPosition)}`;
  } catch (e) {
    return value;
  }
};
