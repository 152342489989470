import { FC, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Control as Checkbox, ListView, ListCell } from '@modulor/react';
import { useAppSelector } from 'store/hooks';
import { useCustomTranslation } from 'Modulor/hooks';
import { paymentFlowSelector } from 'store/slices/global';
import {
  Clickstream,
  getClickStreamEvent,
  getClickStreamCashierContext,
  getClickStreamPayGroupCashierContext,
} from 'services/clickstream';

import './CheckboxField.scss';

interface CheckboxFieldProps {
  name: string;
  label?: string | null;
  control: Control;
  isRequired?: boolean;
  isCheckout: boolean;
  placeHolder?: string;
}

export const CheckboxField:FC<CheckboxFieldProps> = (props) => {
  const {
    name,
    label,
    control,
    isRequired,
    isCheckout,
    placeHolder,
  } = props;
  const [toggledSaveCard, toggleSaveCard] = useState(true);
  const { t } = useCustomTranslation();
  const clickstream = Clickstream.use();
  const flowType = useAppSelector(paymentFlowSelector);
  const cashierContext = getClickStreamCashierContext(flowType);
  const payGroupContext = getClickStreamPayGroupCashierContext();

  const handleChangeSendEvent = (isToggled: boolean) => {
    const clEvent = getClickStreamEvent.cashier_method_save_card_checkbox_click(!isToggled);
    clickstream.push(clEvent, [cashierContext,
      payGroupContext]);
    toggleSaveCard(!isToggled);
  };

  return (
    isCheckout ? (
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ListView>
            <ListCell
              key={name}
              text={t('PH.DEPOSIT.SAVE_CARD') || ''}
              onClick={() => {
                handleChangeSendEvent(toggledSaveCard);
                field.onChange(!toggledSaveCard);
              }}
              content={(
                <div className="checkbox__in-list-cell">
                  <Checkbox
                    {...field}
                    type="checkbox"
                    name={name}
                    placeholder={t(placeHolder)}
                    checked={toggledSaveCard}
                    value={t(label || name)}
                  />
                </div>
                        ) as unknown as string}
            />
          </ListView>
        )}
      />
    ) : (
      <Controller
        name={name}
        control={control}
        rules={{
          required: isRequired,
        }}
        render={({ field }) => (
          <Checkbox
            {...field}
            type="checkbox"
            name={name}
            checked={field.value}
            value={t(label || name)}
            placeholder={t(placeHolder)}
          />
        )}
      />
    )
  );
};
