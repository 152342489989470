import { useEffect, lazy, Suspense } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setParamsSnapshot,
  setDemeterFlow,
  setAppChannel,
  checkAndSetIsSessionExpired,
  setInitialTheme,
} from 'store/slices/global';
import { getInitialTheme } from 'utils';
import { FlowTypes, UIThemeTypes } from 'store/constants';
import ErrorBoundary from 'Modulor/components/ErrorPage/ErrorBoundary';
import { useMetricsRequest } from 'Modulor/hooks';
import { getAppChannel } from './utils';
import { initSentry } from './services/sentry';
import ModulorLoader from './Modulor/components/ModulorLoader';

import './index.scss';

const sentryDSN = process.env.REACT_APP_SENTRY_DSN;
if (sentryDSN) {
  initSentry(sentryDSN);
}

const ModulorApp = lazy(() => import('Modulor/ModulorApp'));

const App = () => {
  const UITheme = useAppSelector(({ common }) => common.UITheme);
  const dispatch = useAppDispatch();
  useMetricsRequest();

  useEffect(() => {
    const { search, href } = window.location;
    const flow = href.includes('deposit') ? FlowTypes.deposit : FlowTypes.withdrawal;
    const initialTheme = getInitialTheme();
    const appChannel = getAppChannel();

    dispatch(setDemeterFlow(flow));
    dispatch(setAppChannel(appChannel));
    dispatch(setParamsSnapshot(search));
    dispatch(checkAndSetIsSessionExpired());
    dispatch(setInitialTheme(initialTheme));
  }, []);

  if (UITheme === UIThemeTypes.modulor) {
    return (
      <ErrorBoundary>
        <Suspense fallback={<ModulorLoader />}>
          <ModulorApp />
        </Suspense>
      </ErrorBoundary>
    );
  }

  return null;
};

export default App;
