import { FC, useEffect, useState } from 'react';
import { Chip, Chips } from '@modulor/react';
import {
  Clickstream,
  getClickStreamCashierContext,
  getClickStreamEvent,
  getClickStreamPayGroupCashierContext,
} from 'services/clickstream';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { paymentFlowSelector, setAmount } from 'store/slices/global';
import { getSymbolFromCurrency } from 'utils';
import { getPrettierFormatNumber } from 'utils/dataTransform';
import { getInitialSelectedAmountIndex } from './utils';

interface AmountBlocksProps {
  currentAmount: number;
  amountBlocks: number[] | null;
  onClick: (value: number) => void;
  currency: string;
  isAutoSubmit?: boolean;
}

export const AmountBlocks: FC<AmountBlocksProps> = (props) => {
  const {
    currentAmount,
    amountBlocks,
    onClick,
    currency,
    isAutoSubmit,
  } = props;
  const dispatch = useAppDispatch();
  const clickstream = Clickstream.use();
  const flowType = useAppSelector(paymentFlowSelector);
  const cashierContext = getClickStreamCashierContext(flowType);
  const payGroupContext = getClickStreamPayGroupCashierContext();
  const currencySymbol = getSymbolFromCurrency(currency);
  const initialSelectedAmountIndex = getInitialSelectedAmountIndex(amountBlocks);
  const initialSelectedAmount = amountBlocks ? amountBlocks[initialSelectedAmountIndex] : 0;
  const [selectedAmount, setSelectedAmount] = useState<number | null>(initialSelectedAmount);
  const [ready, setReady] = useState<boolean>(false);
  const onClickHandler = (value: number, index: number) => {
    if (!isAutoSubmit) {
      const сlEvent = getClickStreamEvent.cashier_method_predefined_amount_button_click(index, currency, value);
      clickstream.push(сlEvent, [cashierContext,
        payGroupContext]);
      setSelectedAmount(value);
      onClick(value);
      dispatch(setAmount(`${value}`));
    }
  };

  useEffect(() => {
    if (typeof selectedAmount === 'number') {
      onClickHandler(selectedAmount, initialSelectedAmountIndex);
      setReady(true);
    }
  }, []);

  useEffect(() => {
    if (amountBlocks && !amountBlocks.includes(currentAmount) && ready) {
      setSelectedAmount(null);
    }
  }, [
    currentAmount,
    ready,
  ]);

  if (!amountBlocks) {
    return null;
  }

  return (
    <div className="amount-blocks">
      <Chips
        dataTestId="amount-blocks"
        horizontal
      >
        {amountBlocks.map((value, index) => (
          <Chip
            key={value}
            className="amount-blocks__item"
            onClick={(e) => {
              e.preventDefault();
              onClickHandler(value, index);
            }}
            checked={value === selectedAmount}
            value={getPrettierFormatNumber(value)}
          >
            {`${getPrettierFormatNumber(value)} ${currencySymbol || currency}`}
          </Chip>
        ))}
      </Chips>
    </div>
  );
};
