import { Params } from 'react-router-dom';
import { PayHubDomainSessionsEnumsPlatformType } from 'store/models';
import {
  autoReinit,
  getMerchantRedirectUrl,
  inIframe,
  PAGE_STATUS,
  parseQuery,
  softLocalStorageGet,
  urlFormer,
} from 'utils';

export const onReloadButtonClickHandler = (platform: PayHubDomainSessionsEnumsPlatformType) => {
  const isPlatformNative:boolean | null = platform ? platform.includes('native') : null;
  const isInIframe = inIframe();
  const params = parseQuery(window.location.search) as Params;
  const {
    isApplePayAvailable,
    isGpayAvailable,
  } = params;

  const isGooglePayAvailability:boolean = isGpayAvailable === 'true';
  const isApplePayAvailability:boolean = isApplePayAvailable === 'true';

  const isNativeDevice:boolean = (isApplePayAvailability && !isInIframe)
    || (isGooglePayAvailability && !isInIframe)
    || !!isPlatformNative;

  if (!isInIframe && !isNativeDevice) {
    const merchantRedirectUrl = getMerchantRedirectUrl();
    const merchantRedirectType = softLocalStorageGet('merchant_redirect_type');
    const decodedUrl = merchantRedirectUrl ? decodeURI(merchantRedirectUrl) : false;
    const merchantUrl = softLocalStorageGet('merchant_url') ?? params.merchant_domain;
    const replaceURL = decodedUrl || urlFormer(merchantUrl, PAGE_STATUS.error);

    if (
      typeof merchantRedirectType === 'string'
      && merchantRedirectType?.toLowerCase() === 'blank'
      && decodedUrl
    ) {
      window.open(replaceURL, '_blank');
    } else if (replaceURL) {
      window.location.assign(replaceURL);
    }

    return;
  }

  autoReinit({
    isSubmitted: true,
    isNativeDevice,
  });
};
