import { FC, FormEvent } from 'react';
import { Control, Controller } from 'react-hook-form';
import { TextField } from '@modulor/react';
import { useCustomTranslation } from 'Modulor/hooks';
import { FlowTypes } from 'store/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setAmount, userBalanceSelector } from 'store/slices/global';
import { getPattern, getPrettierFormatNumber, getSymbolFromCurrency } from 'utils';
import { getAmountErrorKey } from '../fieldErrors';

interface AmountFieldProps {
  name: string;
  label?: string | null;
  control: Control;
  min?: number;
  max?: number;
  regexp?: string;
  placeHolder?: string;
  currency: string;
  flowType: FlowTypes;
  isDisabled?: boolean;
}

export const AmountField:FC<AmountFieldProps> = (props) => {
  const {
    name,
    control,
    min,
    max,
    regexp,
    currency,
    flowType,
    placeHolder = '',
    isDisabled,
  } = props;
  const { t } = useCustomTranslation();
  const dispatch = useAppDispatch();
  const userBalance = useAppSelector(userBalanceSelector);
  const pattern = getPattern(regexp);

  const currencySymbol = getSymbolFromCurrency(currency) || '';
  const placeholderMessage = t('PH.LABEL.LIMIT_AMOUNT_HINT', {
    min: getPrettierFormatNumber(min),
    max: getPrettierFormatNumber(max) || '∞',
    currency_icon: currencySymbol,
  });

  const isAmountAboveUserBalance = (amount: number) => {
    if (flowType === FlowTypes.withdrawal && amount > userBalance) {
      return getAmountErrorKey(flowType, 'isAmountAboveUserBalance');
    }
    return true;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: true,
        min,
        max,
        pattern,
        validate: {
          isAmountAboveUserBalance,
        },
      }}
      render={({ field, fieldState }) => {
        const amountForErrorText = fieldState.error?.type === 'max' ? max : min;
        const errorMessageKey = getAmountErrorKey(flowType, fieldState.error?.type);
        const errorMessage = t(errorMessageKey, {
          amount: `${getPrettierFormatNumber(amountForErrorText)} `,
          currency_icon: currencySymbol,
        });
        const onChangeHandler = (value: FormEvent<HTMLDivElement> | string) => {
          let result = value;

          if (typeof value === 'string') {
            // NOTE: . (dot) as separator
            result = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          }
          dispatch(setAmount(`${result}`));
          field.onChange(result);
        };
        const value = field.value ? `${field.value}` : '';

        return (
          <TextField
            {...field}
            data-testid="amount-field"
            value={getPrettierFormatNumber(value)}
            onChange={onChangeHandler}
            inputMode="numeric"
            name={name}
            error={errorMessage}
            label={placeholderMessage}
            placeholder={t(placeHolder)}
            type="text"
            maxLength={10}
            disabled={isDisabled}
          />
        );
      }}
    />
  );
};
