import cardValidator from 'card-validator';
import { exoticCards, expandedVisaCard } from 'utils/exoticCardsConstants';
import { FieldValues, UseFormClearErrors, UseFormSetError } from 'react-hook-form';
import {
  Limits,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedTypes,
} from 'store/models';
import { TFunction } from 'commonTypes';
import { Option } from 'store/models/paymentProcessModels';

interface CardPanOnBlurHandlerProps {
  setError?: UseFormSetError<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
}

interface OnBLureEvent {
  target: {
    name: string;
    value: string;
  };
}

interface GetAdditionalRulesProps {
  extendedType?: string;
  setError?: UseFormSetError<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
}

interface GetAmountBlocksProps {
  amountBlocks: number[] | null | undefined;
  gbResult: { amountBlocks: number[] | null } | null;
  limits: Limits | null;
}

const cardPanOnBlurHandler = (props: CardPanOnBlurHandlerProps) => (event: OnBLureEvent) => {
  const {
    setError,
    clearErrors,
  } = props;

  if (clearErrors) {
    clearErrors(event.target.name);
  }

  if (!cardValidator.number(event.target.value).isValid && setError) {
    setError(event.target.name || '', {
      type: 'manual',
      message: 'PH.ERROR.INCORRECT_CARD_NUMBER',
    });
  }
};

const cardPanValidationFunction = (value: string) => {
  if (!value) {
    return false;
  }
  if (value.length < 16) {
    return cardValidator.number(value).isPotentiallyValid;
  }
  return cardValidator.number(value).isValid;
};

const expirationDateValidationObject = {
  minLength: 2,
  maxLength: 2,
};

const CvvValidationObject = {
  required: true,
  minLength: 3,
  maxLength: 3,
};

interface GetAdditionalRulesProps {
  extendedType?: string;
  setError?: UseFormSetError<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
}

export const getAdditionalRules = (props: GetAdditionalRulesProps): Record<string, number | unknown> => {
  const {
    extendedType,
    setError,
    clearErrors,
  } = props;

  if (!extendedType) {
    return {};
  }

  const isCvv = extendedType === ExtendedTypes.CardCvv;
  const isCardPan = extendedType === ExtendedTypes.CardPan || extendedType === ExtendedTypes.CreditCard;

  exoticCards.forEach((type) => cardValidator.creditCardType.addCard(type));
  expandedVisaCard.forEach((type) => cardValidator.creditCardType.addCard(type));

  if (isCvv) {
    return CvvValidationObject;
  }

  if ([
    ExtendedTypes.CardExpYear,
    ExtendedTypes.CardExpMonth,
  ].includes(extendedType as ExtendedTypes)) {
    return expirationDateValidationObject;
  }

  if (isCardPan) {
    return {
      validate: cardPanValidationFunction,
      onBlur: cardPanOnBlurHandler({
        setError, clearErrors,
      }),
    };
  }

  return {};
};

export const MAX_CARD_LENGTH_WITH_SPACES = 19;

export const allowOnlyNumericValue = (value: string): string => value.replace(/\D/g, '');

export const formatCardNumber = (value: string) => {
  if (!value) {
    return '';
  }

  const sanitizedValue = allowOnlyNumericValue(value);
  if (sanitizedValue) {
    return sanitizedValue.match(/.{1,4}/g)?.join(' ').substring(0, MAX_CARD_LENGTH_WITH_SPACES);
  }

  return '';
};

export const getInitialSelectedAmountIndex = (amountBlocks?: number[] | null) => {
  const isAmountBlocksArray = Array.isArray(amountBlocks);
  return isAmountBlocksArray && amountBlocks?.length > 1 ? 1 : 0;
};

export const buildModulorSelectOptions = (options: Option[], t: TFunction) => ([
  {
    name: t('PH.SELECT.VALUE.MODULOR.DROPDOWN'),
    value: '',
  },
  ...options,
]);

export const getAmountBlocksByExperiment = (props: GetAmountBlocksProps) => {
  const {
    amountBlocks,
    gbResult = null,
    limits,
  } = props;
  const { min = 0, max = Infinity } = limits || {};
  const gbAmountBlocks = gbResult?.amountBlocks || null;

  if (!gbAmountBlocks) return amountBlocks;
  const isGbAmountBlocksValid = gbAmountBlocks.every((value: number) => value >= min
      && value <= max);

  return isGbAmountBlocksValid ? gbAmountBlocks : amountBlocks;
};
