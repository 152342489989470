/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import { useLazyContentPageQuery } from 'store/services/contentPageAPI';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { brandSelector, langSelector, userIdSelector } from 'store/slices/global';
import { BottomSheet, Button, Divider, Icon, Loader, NavigationBar, Toast } from '@modulor/react';
import { closeContentPageUrl, contentPageUrlSelector, isContentPageOpenSelector } from 'store/slices/contentPage';
import { getContent, formContent, inIframe } from 'utils';
import { useCustomTranslation, useFrameChangeHeight } from 'Modulor/hooks';
import clx from 'classnames';

export const ContentController = () => {
  const { t } = useCustomTranslation();
  const dispatch = useAppDispatch();
  const brand = useAppSelector(brandSelector);
  const lang = useAppSelector(langSelector).toLowerCase();
  const url = useAppSelector(contentPageUrlSelector);
  const isOpen = useAppSelector(isContentPageOpenSelector);
  const userId = useAppSelector(userIdSelector);
  const [trigger, result] = useLazyContentPageQuery();
  const { data = [], isFetching, isError, isSuccess, error } = result;
  const closeButtonText = t('PH.BUTTON.ACCEPT') || '';
  const [content, setContent] = useState<string>('');
  useFrameChangeHeight(isSuccess || isError);
  const onClose = () => dispatch(closeContentPageUrl());

  useEffect(() => {
    if (isOpen) {
      trigger({
        url,
        userId,
      });
    }
  }, [
    isOpen,
    url,
    userId,
  ]);

  useEffect(() => {
    if (!isFetching && isOpen && data.length) {
      const currentContent = getContent(data, lang, brand);
      setContent(formContent(currentContent, userId));
    }
  }, [
    isFetching,
    isOpen,
    data,
  ]);

  return (
    <>
      {isOpen && isError && (
        <Toast
          variant="error"
          visible={Boolean(isError)}
          text={t(error.toString())}
          multiline
          rightIcon={(
            <Icon
              name="close"
            />
          )}
          duration={2500}
          onHide={() => {}}
        />
      )}
      <BottomSheet
        open={isOpen}
        onShadowClick={onClose}
        onSwipeDown={onClose}
        header={(
          <>
            <NavigationBar
              transparent
              title={t('PH.TITLE.INFORMATION')}
              right={(
                <Icon
                  name="close"
                  onClick={onClose}
                />
            )}
            />
            <Divider className={clx('header-divider', {
              'header-divider__in-iframe': inIframe(),
            })}
            />

          </>
        )}
        buttons={(
          <Button
            variant="primary"
            onClick={onClose}
            label={closeButtonText}
          />
        )}
      >
        <div className="content-page-wrapper">
          {isFetching && <Loader />}
          <div
            id="content-anchor"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </BottomSheet>
    </>
  );
};
