import { FC, useEffect } from 'react';
import { Button, ListView, ListCell, Icon } from '@modulor/react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  useCustomTranslation,
  useDetectNewErrors,
  useFrameChangeHeight,
  useGetTaxInfo,
} from 'Modulor/hooks';
import { FlowTypes } from 'store/constants';
import { FieldValues, UseFormSetError, useForm } from 'react-hook-form';
import {
  Limits,
  Parameter,
  PayHubAdminPanelDomainCurrenciesEnumsCashierMethodParamExtendedType as ExtendedType,
  PayHubCashierContractsViewDataResponse,
  MerchantUserFeeResponce, ProcessingCurrency,
} from 'store/models';
import {
  setWithdrawalCardsList,
  setSelectedWithdrawalCard,
  setVerifyNewPayoutAccount,
  withdrawalSelectedCardSelector,
  isSelfTargetSelector,
  methodNameSelector, setExtendedTypeForCardParam,
  setBetShopOptions,
} from 'store/slices/withdrawal';
import { TaxContainer } from 'Modulor/components/Tax/TaxContainer';
import { platformSelector, userBalanceSelector } from 'store/slices/global';
import {
  Clickstream,
  getClickStreamEvent,
  getClickStreamCashierContext,
  getClickStreamPayGroupCashierContext,
} from 'services/clickstream';
import { isBetshopCheckboxField, prepareFieldsForRender } from 'Modulor/OTP/utils';
import { getFormDefaultValues, getRequisiteFieldNameList, getSubmitButtonVariant, getSymbolFromCurrency } from 'utils';
import { isOTPEnabledSelector } from 'store/slices/otp';
import { getPrettierFormatNumber } from 'utils/dataTransform';
import { renderFields } from '../Checkout/utils';
import { InfoLink } from '../components/InfoLink';
import AdditionalInfo from '../components/AdditionalInfo';
import { SelectedCard } from './Cards/SelectedCard';
import { FieldConfig, fieldGetter, getAmountField, getCardField } from '../fieldGetter';
import { AmountBlocks } from '../components/AmountBlocks';
import { Fee } from '../components/Fee';
import { excludeOTPField } from '../Deposit/utils';

import './WithdrawalForm.scss';
import { getMaxLimit, getWithdrawalSubmitSubText } from './utils';
import { ConversionBlock } from '../components/ConversionBlock';
import { getGBFeatures } from '../../services/growthbook';
import { getAmountBlocksByExperiment } from '../components/utils';
import { ProcessingTime } from '../components/ProcessingTime';

type FormConditions = {
  payoutAccountVerifBlocked: boolean;
};
interface WithdrawalFormProps {
  parameters: Parameter[];
  amountBlocks?: number[];
  amountRegExpFe?: string;
  limits: Limits | null;
  submitCallback: (
    fields: FieldValues,
    setError: UseFormSetError<FieldValues>,
    dirtyFields: Partial<Record<keyof FieldValues, boolean>>,
  ) => void;
  merchantUserFee?: MerchantUserFeeResponce;
  fee?: string;
  cardParam?: Parameter;
  currency: string;
  isFetchingSubmitRequest: boolean;
  additionalViewData?: PayHubCashierContractsViewDataResponse[] | null;
  processingCurrency?: ProcessingCurrency;
  processingCurrencyRate?: number;
  descriptionUrl?: string;
  formConditions: FormConditions;
  processingTimeTranslationKey?: string;
}

const gbFeatures = getGBFeatures();

export const WithdrawalForm: FC<WithdrawalFormProps> = (props) => {
  const {
    submitCallback,
    amountBlocks,
    amountRegExpFe,
    limits,
    merchantUserFee,
    fee,
    cardParam,
    currency,
    parameters,
    isFetchingSubmitRequest,
    additionalViewData,
    processingCurrency,
    processingCurrencyRate,
    descriptionUrl,
    formConditions,
    processingTimeTranslationKey,
  } = props;
  const dispatch = useAppDispatch();
  const selectedCard = useAppSelector(withdrawalSelectedCardSelector);
  const methodName = useAppSelector(methodNameSelector);
  const platform = useAppSelector(platformSelector);
  const isSelfTarget = useAppSelector(isSelfTargetSelector);
  const isOTPEnabled = useAppSelector(isOTPEnabledSelector);
  const userBalance = useAppSelector(userBalanceSelector);
  const formConfig = prepareFieldsForRender(parameters, formConditions, cardParam);
  const filteredFormConfig = excludeOTPField(isOTPEnabled, formConfig);
  const requisiteFieldNameList = getRequisiteFieldNameList(formConfig);
  const defaultValues = getFormDefaultValues(filteredFormConfig);
  const { t } = useCustomTranslation();
  const gbAmountBlocksResult = gbFeatures.growthBook.evalFeature('billing.recommended.amount');
  const amountBlocksData = getAmountBlocksByExperiment({
    amountBlocks, limits, gbResult: gbAmountBlocksResult?.value,
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    trigger,
    formState: {
      isValid,
      errors,
      dirtyFields,
    },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });
  const clickstream = Clickstream.use();
  const cashierContext = getClickStreamCashierContext(FlowTypes.withdrawal);
  const payGroupContext = getClickStreamPayGroupCashierContext();
  const currencySymbol = getSymbolFromCurrency(currency);
  const amount = parseFloat(watch('amount')) || 0;
  const { tax, calculateNonTaxableAmount, amountWithTaxes } = useGetTaxInfo(
    amount,
    FlowTypes.withdrawal,
  );

  const hasAmountBlocks = !!amountBlocksData?.length;
  const isPamAvailable = (cardParam && cardParam?.verifyNewPayoutAccount);
  const cardField = cardParam
    && ((isPamAvailable || selectedCard.isNew)
      ? getCardField(cardParam)
      : cardParam
    ) as FieldConfig;

  const { hasNewErrors } = useDetectNewErrors(errors);
  useFrameChangeHeight(hasNewErrors || !!cardParam || !!tax);

  const setAmount = (amountFieldValue: number) => setValue('amount', amountFieldValue, {
    shouldValidate: true,
  });
  const max = getMaxLimit(limits, userBalance);
  const amountField = getAmountField({
    amountRegExpFe,
    limits: {
      ...limits,
      max,
    },
    currency,
    flowType: FlowTypes.withdrawal,
  });

  useEffect(() => {
    const eventOpen = getClickStreamEvent.cashier_method_open;
    clickstream.push(eventOpen, [cashierContext,
      payGroupContext]);

    if (requisiteFieldNameList.length) {
      requisiteFieldNameList.forEach((name) => trigger(name));
    }
    const betShopField = formConfig.find((parameter: Parameter) => parameter.extendedType === ExtendedType.BetShop);
    if (betShopField?.options) {
      dispatch(setBetShopOptions(betShopField.options));
    }
  }, []);

  useEffect(() => {
    const betShopCheckboxField = parameters.find(((field: Parameter) => isBetshopCheckboxField(field)));
    if (betShopCheckboxField) {
      setValue(betShopCheckboxField.name, true);
    }
  }, [parameters]);

  useEffect(() => {
    dispatch(setVerifyNewPayoutAccount(!!cardParam?.verifyNewPayoutAccount));
    dispatch(setExtendedTypeForCardParam(cardParam?.extendedType as ExtendedType));
  }, [isPamAvailable]);

  useEffect(() => {
    if (
      !selectedCard.value
      && !selectedCard.isNew
      && !!cardParam?.options?.length
    ) {
      dispatch(setSelectedWithdrawalCard(cardParam?.options[0]));
    }
    if (cardParam?.options?.length) {
      dispatch(setWithdrawalCardsList(cardParam.options));
    }
  }, [cardParam,
    selectedCard,
    dispatch]);

  useEffect(() => {
    if (cardParam) {
      const event = getClickStreamEvent.cashier_payout_entry_page_view;
      clickstream.push(event, [cashierContext,
        payGroupContext]);
    }
  }, [cardParam]);

  const displayCardSection = !!cardParam && cardParam.options?.length;
  const isNewCardSelected = cardField && selectedCard.isNew;
  const showCardField = isNewCardSelected;
  const accManagementHint = t('PH.WITHDRAWAL.TOOLTIP.VERIFICATION_CODE');
  const preparedFields = renderFields({
    clearErrors,
    setError,
    setValue,
    fields: filteredFormConfig,
    control,
  });
  const submitSubText = getWithdrawalSubmitSubText({
    t,
    amount,
    currency,
    taxAmount: amountWithTaxes?.taxAmount,
    processingCurrency,
    processingCurrencyRate,
  });

  return (
    <form
      className="withdrawal-form"
      onSubmit={handleSubmit((fields) => submitCallback(fields, setError, dirtyFields))}
    >
      <div className="withdrawal-form__content">
        {!!displayCardSection && <SelectedCard selectedCard={selectedCard} />}
        {fieldGetter({
          clearErrors,
          setError,
          setValue,
          formField: {
            control,
          },
        })(amountField)}
        {hasAmountBlocks && (
          <AmountBlocks
            currentAmount={amount}
            amountBlocks={amountBlocksData}
            onClick={setAmount}
            currency={currency}
          />
        )}
        <ProcessingTime message={processingTimeTranslationKey} />
        <Fee
          fee={fee}
          merchantUserFee={merchantUserFee}
        />
        <TaxContainer
          tax={tax}
          isTaxHintShown
          calculateNonTaxableAmount={calculateNonTaxableAmount}
          taxAmount={amountWithTaxes.taxAmount}
          amount={amount}
        />
        <ConversionBlock
          amount={amount}
          methodName={methodName}
          processingCurrency={processingCurrency}
          processingCurrencyRate={processingCurrencyRate}
        />
        {preparedFields}
        {cardField && showCardField
          && fieldGetter({
            clearErrors,
            setError,
            setValue,
            formField: {
              control,
            },
          })(cardField)}
        {showCardField && (
          <ListView className="card-box">
            <ListCell
              subtitle={accManagementHint}
              image={<Icon name="info_circle_outlined" />}
              multiline
            />
          </ListView>
        )}
      </div>
      <div className="withdrawal-form__footer">
        <InfoLink
          methodName={methodName}
          platform={platform}
          descriptionUrl={descriptionUrl}
          isSelfTarget={isSelfTarget}
          flowType={FlowTypes.withdrawal}
        />
        <AdditionalInfo additionalData={additionalViewData} />
        <Button
          className="submit-button"
          type="submit"
          disabled={!isValid || !!Object.keys(errors).length}
          loading={isFetchingSubmitRequest}
          variant={getSubmitButtonVariant(isValid)}
          doubleText={submitSubText}
        >
          {t('PH.BUTTON.WITHDRAW_WITH_AMOUNT', {
            amount: getPrettierFormatNumber(amount),
            currency_icon: currencySymbol,
          })}
        </Button>
      </div>
    </form>
  );
};
